import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { Link, useHistory } from "react-router-dom";
import {
  FaSignOutAlt,
  FaUserMd,
  FaMicroscope,
  FaPills,
  FaTasks,
  FaList,
  FaChartBar,
  FaNotesMedical,
  FaArrowLeft,
} from "react-icons/fa";

import { useAuth } from "../../hooks/auth";

import { ButtonCircle } from "../Form/styles";

import logo from "../../assets/calitool.png";

function Topnav({ title = "", children = [], fixedChildLayout=true, expandTitle=false, enableBackButton=false }) {
  const { user } = useAuth();
  const { name, gender } = user;

  const history = useHistory();

  const date = new Date("2023-10-08T18:26:02.193Z");

  return (
    <>
      {
        fixedChildLayout?
        <div style={{marginTop:'150px'}}></div>
        :
        <></>
      }
      <div className="container-fluid px-4 py-3 shadow topnav-component" style={fixedChildLayout?{position: 'fixed',
                                                zIndex: 99,backgroundColor: '#f8f9fa',}:{}}>
        <div className="row">
          <div className={"col-sm-12 "+ (expandTitle ? '':  "col-md-4")}>
            <div>
              {
                enableBackButton
                && 
                <FaArrowLeft size={25} onClick={()=>history.goBack()} style={{cursor:'pointer', verticalAlign:'sub', marginRight:'10px'}} />
              }
              {" "}
              <h1 style={{display:'inline-block'}}>{title}</h1>
            </div>
            Hello, {name}
            <br></br>
            <small className="text-muted">
              Last updated on {date.toLocaleString("en-US")}
            </small>
          </div>

          <div className="col-sm-12 col-md-5">
            <div className="row" >
              <div style={{display: 'inline-block'}}>
                <span style={fixedChildLayout? {
                                                padding: '17px 20px',
                                                margin: '-17px',
                                                width: '100%',
                                                
                                                borderRadius: '5px',
                                                display: 'inline-block'} : {}}>
                  {children}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Topnav;
