import React, { useState, useEffect } from "react";

import SideBar from "../../../components/SideBar";

import { useAuth } from "../../../hooks/auth";
import { Container } from "../../Landing/styles";

import Topnav from "../../../components/Topnav";
import { Line } from "react-chartjs-2";

import api from "../../../services/api";
import { FaCircleNotch } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

function AdminDashboard() {

  const { token, user } = useAuth();

  const history = useHistory();

  if(user.profile != 'Administrator'){
    history.replace('/landing');
  }

  const [loading, setLoading] = useState(true);
  const [panelState, setPanelState] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [cards, setCards] = useState([]);

  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const [cardLoading, setCardLoading] = useState({});
  const [cardData, setCardData] = useState({});

  const [reviewAction, setReviewAction] = useState([]);

  const [enableReview, setEnableReview] = useState(false);

  var shouldLoad = false;
  var intervalID = 0;

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    load();
    document.addEventListener("visibilitychange", event => {
      if (document.visibilityState == "visible") {
        enterPage();
      } else {
        exitPage();
      }
    });
    enterPage();
  }, []);

  function exitPage(){
    shouldLoad = false;
    clearInterval(intervalID);
  }
  function enterPage(){
    shouldLoad = true;
    intervalID = setInterval(()=>{
      if(shouldLoad){
        load();
      }else{
      }
    }, 1000 * 60);
  }

  function load(){

    Promise.all([
      api.get('/administrativeResources/dashboard?timezone='+timezone).then((e)=>{
        setDashboard(e.data);
      }),
      api.get('/administrativeResources/cards?timezone='+timezone).then((e)=>{
        setCards(e.data);
        setEnableReview(e.data.find((e)=>e.path == "/administrativeResources/cards/PendingActionPlans")?.path)
      }),
      api.get('/administrativeResources/actionPlans/pending').then((e)=>{
        setReviewAction(e.data);
      })
    ]).then((e)=>{
      setLoading(false);
    });
  }

  function loadCard(card){
    let c = {...cardLoading};
    c[card] = true;
    setCardLoading(c);
    api.get(card+'?timezone='+timezone).then((e)=>{
      let c = {...cardLoading};
      c[card] = false;
      setCardLoading(c);
      
      let d = {...cardData};
      d[card] = e.data;
      setCardData(d);
    });
  }

  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title="Administrative Panel"
          fixedChildLayout={true}
          children={
            <>
              {
                enableReview && 
                <Link to={'/admin/dashboard/detail?about='+enableReview} onClick={()=>{
                  localStorage.setItem('adminPanelTitle', "Review Action Plans");
                }} className='btn btn-primary' style={{backgroundColor:"#212121", color:"white", float:"right"}}>
                  Review Action Plans
                </Link>
              }
            </>
          }
        />
        {
          loading && 
          <center>
            <FaCircleNotch size={50} className='icon-spin mt-5' />
          </center>
        }

        {
          !loading && 
          <>
            <div className='row ms-4 px-md-5 mx-md-5'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-body p-0'>
                    <div className='row'>
                      <div className='col-12 col-md-8 px-md-5 py-md-4'>
                        <h2>Access in the system today</h2>
                        <p className='text-muted'>as of {new Date().toLocaleString()}</p>

                        {
                          <Line 
                            options={{
                              responsive: true,
                              scales: {
                                xAxes: [{
                                  gridLines: {
                                     display: false
                                  }
                               }],
                               yAxes: [{
                                  gridLines: {
                                     display: false
                                  }
                               }]
                              }
                            }} 
                            data={{
                              labels: dashboard.dailyAccess.sort(function(a, b){return Number(a.hour)-Number(b.hour)}).map((e)=>e.hour),
                              datasets: [
                                {
                                  label: 'Today',
                                  data: dashboard.dailyAccess.sort(function(a, b){return Number(a.hour)-Number(b.hour)}).map((e)=>e.total),
                                  borderColor: '#044c6e',
                                  backgroundColor: 'transparent',
                                  tension: 0.4
                                },
                                // {
                                //   label: 'Yesterday',
                                //   data: data.dashboard.graph[1],
                                //   borderColor: '#1797d3',
                                //   backgroundColor: 'transparent',
                                //   tension: 0.4
                                // },
                              ],
                            }} 
                          />
                        }

                      </div>
                      <div className='col-12 col-md-4'>
                        {
                          [
                            ['Total system logins',dashboard.totalLogins],
                            ['Total Downloads',dashboard.totalAssessmentsDownloads],
                            ['Total system errors',dashboard.totalErrors],
                            ['Active drafts',dashboard.activeDrafts],
                            ['Logins today',dashboard.loginsToday],
                          ].map((e,i)=>(
                              <div key={i} className='d-block border p-3 text-center'>
                                <p className='text-muted'>{e[0]}</p>
                                <h3>{e[1]}</h3>
                              </div>
                          ))
                        }

                      </div>
                    </div>
                  </div>
                </div> 
              </div>

              <div className='col-12 mt-4'>
                <div className='row'>
                  {
                    cards.map((e,i)=>(
                      <div key={i} className={'col-12 col-md-6 px-4 my-3 animatedContainer'}>
                        <div className='card h-100'>
                          <div className='card-body p-4 h-100'>
                            <div className='row'>
                              <div className='col-8'>
                                <h2>{e.label}</h2>
                                <p className='text-muted'>
                                  {
                                    panelState[e.label] ? 'All':'Recent'
                                  }
                                </p>
                              </div>
                              <div className='col text-end'>
                                <Link to={'/admin/dashboard/detail?about='+e.path} onClick={()=>{
                                  localStorage.setItem('adminPanelTitle', e.label);
                                }} className='btn btn-link'>
                                  View all
                                </Link>
                                {/* <button className='btn btn-link' onClick={()=>{
                                  if(!panelState[e.label]){
                                    panelState[e.label] = true;
                                    setPanelState({...panelState});
                                    loadCard(e.path);
                                  }else{
                                    panelState[e.label] = false;
                                    setPanelState({...panelState});
                                  }
                                }}>
                                  {
                                    panelState[e.label] ? 'View recent':'View all'
                                  }
                                </button> */}
                              </div>
                            </div>

                            <div className='row'>
                              <div className='col'>
                                {
                                  cardLoading[e.path] && 
                                  <center>
                                    <FaCircleNotch size={50} className='icon-spin mt-5' />
                                  </center>
                                }
                                <div className='table-responsive animatedContainer' style={{maxWidth:'75vw', maxHeight:'400px'}}>

                                  <table className='table table-borderless'>
                                    <tbody>
                                      {
                                        !panelState[e.label]
                                        && 
                                        e.events.map((e,i)=>(
                                          <tr key={i} className='m-fadeout m-fadeIn'>
                                            <td style={{fontSize:'16px'}} className='text-start'>
                                              {e.name ?? e.user ?? e.actionPlan}
                                            </td>
                                            <td style={{fontSize:'16px'}} className='text-muted text-end'>
                                              {e.rota ?? e.date}
                                            </td>
                                          </tr>
                                        ))
                                      }

                                      {
                                        panelState[e.label]
                                        && 
                                        (cardData[e.path] ?? []).map((e,i)=>(
                                          <tr key={i}  className='m-fadeout m-fadeIn'>
                                            <td style={{fontSize:'16px', wordWrap: 'break-word'}} className='text-start'>
                                              {e.logName}
                                            </td>
                                            <td style={{fontSize:'16px', wordWrap: 'break-word'}} className=''>
                                              {e.path}
                                            </td>
                                            <td style={{fontSize:'16px', wordWrap: 'break-word'}} className=''>
                                              {e.message}
                                            </td>
                                            <td style={{fontSize:'16px', wordWrap: 'break-word'}} className='text-muted'>
                                              {e.date}
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </>
        }

      </Container>
    </>
  );
}

export default AdminDashboard;
