

let possibilities = {
    region: [
        'WEST','NORTH','EAST', 'SOUTH'
    ],
    agency: [
        'AGENCY 1', 'AGENCY 2', 'AGENCY 3'
    ],
    country: [
        'ARGENTINA', 'SAN MARTIN', 'HAITI', 'BRAZIL',
    ],
    sector: [
        'CONSTRUCTION', 'FINANCIAL'
    ],
    category: [
        'INTERN', 'EXTERN', 'PENDENT'
    ],
    procurement_method: [
        'LOCAL', 'LICITATION'
    ],
    review_type: [
        'MANUAL', 'AUTOMATIC',
    ],
    market_approach: [
        5000, 10000, 100000, 1000000
    ],
    procurement_process: [
        'ONLINE', 'OFFLINE',
    ],
    performanceRating: [
        90, 89,70,99,100,89,100,79
    ],
    performanceRatingNamed: [
        "Highly Satisfactory",
        'Satisfactory',
        'Moderately Satisfactory',
        'Moderately Unsatisfactory',
        'Unsatisfactory',
        'Highly Unsatisfactory',
    ],
};

function randomDate() {
    const [start,end] = [new Date(2012, 0, 1), new Date()];
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

function buildData(name='cod'){
    let data = [


    ];
    for(let x = 0; x < 1000; x++){
        let r = {};
        r[name] = x;
        data.push({
            ...r,
            countries: possibilities.country[Math.floor(Math.random() * 4)],
            performanceRatingNumber: possibilities.performanceRating[Math.floor(Math.random() * 5)],
            projects: "Project nome de teste geração "+x,
            riskFactorBilling: "Risk Factor Rating for "+x,
            cd: ["A","B","C"][Math.floor(Math.random() * 3)],
            yn: ["D","N","Y"][Math.floor(Math.random() * 3)],
            t: [2,3,1][Math.floor(Math.random() * 3)],
            score: [2,3,1][Math.floor(Math.random() * 3)],
            actionPlan: "No related action",
            performanceRating: possibilities.performanceRatingNamed[Math.floor(Math.random() * 6)],
            // last_updated_on: randomDate().toLocaleString('en-US')
        });
    }
    return data;
}


export default function(name='cod'){
    return buildData(name);
};