import React, { useState, useEffect } from "react";
import SideBar from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";
import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import Input from "../../components/Input";
import { Field, Form, Formik } from "formik";
import { ButtonBack, ButtonSubmit, Subtitle } from "../../components/Form/styles";
import { Button, ButtonPrimary } from "../ForgotPassword/styles";
import AgencyForm from "../Agency/form";
import ProjectForm from "../Project/form";
import RiskForm from "../Risk/form";
import SpecialistForm from "../Specialist/form";
import { LocalDining } from "@material-ui/icons";
import { FaCaretRight, FaTrash } from "react-icons/fa";
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";

export default function FormDrafts() {
    const { token, user } = useAuth();

    const history = useHistory();

    if(user.profile == 'ReadOnly'){
        history.replace('/landing');
    }

    const [drafts, setDrafts] = useState([]);

    const [notFound, setNotFound] = useState(false);

    const MySwal = withReactContent(Swal);

    useEffect(() => {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        load();
      }, [token]);

    function load(){
        api.get('/riskAssessments/drafts').then((e)=>{
            setDrafts(e.data);
        }).catch((e)=>{
            setNotFound(true);
        });
    }

    return <>
        <SideBar />
        <Container>
            <Topnav
                title="Form drafts"
                fixedChildLayout={true}
            />

            <div className="container">
                <div className="row ">
                    <div className='col m-3 text-center'>
                        {
                            notFound &&
                            <h2>There's no draft registered by you</h2>
                        }
                        {
                            drafts.length > 0 && !notFound &&
                            <>  
                                <h2>Drafts</h2>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Agency</th>
                                            <th>Project</th>
                                            <th>Assessment Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            drafts.map((e)=><tr>
                                                <td>{e.agencyName}</td>
                                                <td>{e.projectName}</td>
                                                <td>{new Date(Date.parse(e.assessmentDate)).toLocaleString()}</td>
                                                <td>
                                                    <FaTrash size={27} onClick={()=>{
                                                        MySwal.fire({
                                                            title: 'Are you sure?',
                                                            icon:  'question',
                                                            confirmButtonText: 'Yes',
                                                            showCancelButton: true,
                                                            cancelButtonText: 'No'
                                                        }).then(async (result) => {
                                                                if (result.isConfirmed) {
                                                                    await api.delete('/riskAssessments/drafts/'+e._id);
                                                                    load();    
                                                                }
                                                            }
                                                        );
                                                    }} />
                                                    <Link to={'/form/'+e._id}>
                                                        <FaCaretRight size={20} />
                                                    </Link>
                                                </td>
                                            </tr>)
                                        }
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Container>
    </>;
}