import React, { useState, useEffect } from "react";

import SideBar from "../../../components/SideBar";

import { useAuth } from "../../../hooks/auth";
import { Container } from "../../Landing/styles";

import Topnav from "../../../components/Topnav";
import { Line } from "react-chartjs-2";

import api from "../../../services/api";
import { FaCircleNotch } from "react-icons/fa";
import { useLocation, useHistory } from "react-router-dom";
import humanize from "../../../utils/humanize";

function AdminActionPlansDashboard() {

  const { token , user } = useAuth();

  const history = useHistory();

  if(user.profile != 'Administrator'){
    history.replace('/landing');
  }


  const searchParams = new URLSearchParams(useLocation().search);


  const [loading, setLoading] = useState(true);
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    load();

    
  }, []);

  

  function load(){
        let about = searchParams.get('about');
        if(about){
            api.get(about+'?timezone='+timezone).then((e)=>{
              setLoading(false);
              setCardData(e.data);
            });
        }else{
            useHistory.push('/landing');
        }
  }

  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title={"Administrative Panel: "+localStorage.getItem('adminPanelTitle')}
          fixedChildLayout={true}
          expandTitle={true}
          enableBackButton={true}
        />
        {
          loading && 
          <center>
            <FaCircleNotch size={50} className='icon-spin mt-5' />
          </center>
        }

        {
          !loading && 
          <>
            <div className='row'>
                <div className='col m-4'>
                    <div className='table-responsive' style={{maxWidth: '83vw'}}>
                        <table className='table table-borderless'>
                            <thead>
                                {
                                  cardData.length > 0 && 
                                  <tr>
                                    {
                                       Object.keys(cardData[0]).slice(1).map((e,i)=><th key={i} style={{minWidth:120}}>{humanize(e)}</th>)
                                    }
                                  </tr>
                                }
                            </thead>
                            <tbody>
                                {
                                  cardData.map((e,i)=>(
                                    <tr key={i}  className='m-fadeout m-fadeIn'>
                                      {Object.keys(e).slice(1).map((c,i)=><td key={i} style={{fontSize:'16px', wordWrap: 'break-word'}}>{(e[c])}</td>)}
                                    </tr>
                                  ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </>
        }

      </Container>
    </>
  );
}

export default AdminActionPlansDashboard;
