import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link, useHistory } from "react-router-dom";

import Input from "../../components/Input";
import Select from "../../components/Select";
import {FieldLabel} from "../../components/RadioGroup/styles";
import { initialValues } from "./formikProps";

import { useAuth } from "../../hooks/auth";
import { Container, AnimationContainer, Button } from "./styles";
import api from "../../services/api";

import logoImg from "../../assets/calitool.png";
import { Label } from "../../components/Input/styles";

const SignUp = () => {
  const MySwal = withReactContent(Swal);

  const { SignUp } = useAuth();
  const history = useHistory();

  const [country, setCountry] = useState([]);

  const ref = useRef();

  useEffect(()=>{
    if(!country || country.length == 0){
      api.get('/users/selectOptions').then((e)=>{
        setCountry(e.data.country);
      });
    }
  }, [country]);


  async function handleSubmit(values) {
    api.post('/users', {
      name: values.firstName + " " + values.lastName,
      email: values.email,
      isSpecialist: values.isSpecialist,
      country: values.country
    }).then((e) => {
      MySwal.fire("Password sent", "Check your e-mail to complete your sign up.", 'success');
    }).catch((e) => {
      MySwal.fire("Invalid credentials", "Check if it is a valid World Bank e-mail or if you already have an e-mail authentication in your inbox.", 'error');
    })
  }

  return (
    <Container>
      <AnimationContainer>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <img src={logoImg} alt="STEP-X" style={{ width: "50%" }} />
        <Formik
          onSubmit={(values) => handleSubmit(values)}
          validateOnMount
          innerRef={ref}
          initialValues={{
            isSpecialist: false,
            country: ''
          }}
        >
          <Form>
            <p>
              Your password will be sent to your World Bank e-mail and you must access it in order to complete your sign up.
            </p>
            <Field as={Input} name="email" placeholder="E-mail" required />

            <Field
              as={Input}
              name="firstName"
              type="text"
              required
              placeholder="First Name"
            />

            <Field
              as={Input}
              name="lastName"
              type="text"
              placeholder="Last Name"
              required
              className=''
            />

            <Field
              as={Select}
              name="country"
              placeholder=""
              label='Country'
              required
              className='mb-3'
              options={country.map((e)=>({label:e, value:e}))}
            />

            <FieldLabel>This user is a Specialist?</FieldLabel>
            <br />

            <Field
              type='radio'
              name="isSpecialist"
              label="No"
              required
              value={false}
              onChange={(e)=>{
                ref.current.setFieldValue('isSpecialist', false);
              }}
              // checked={ref.current && ref.current.values.isSpecialist === true}
            />
            <FieldLabel style={{paddingLeft:10}}>  No</FieldLabel>
              <br />
            <Field
              type='radio'
              required
              value={true}
              name="isSpecialist"
              onChange={(e)=>{
                ref.current.setFieldValue('isSpecialist', true);
              }}
              // checked={ref.current && ref.current.values.isSpecialist === false}
            />
            <FieldLabel style={{paddingLeft:4}}>Yes</FieldLabel>




            <Button type="submit">Sign Up</Button>
          </Form>
        </Formik>

        <Link to="/">Back to Login</Link>

      </AnimationContainer>
    </Container>
  );
};

export default SignUp;
