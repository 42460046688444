import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import './styles/global.scss';

import Routes from './routes';

import AppProvider from './hooks';


if(process.env.NODE_ENV == "beta" || process.env.REACT_APP_ENVIRONMENT == 'beta'){
  require('./styles/beta.scss');
}

function App() {
  return (
  <AppProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </AppProvider>
  );
}

export default App;