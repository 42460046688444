import React, { useState, useEffect } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { Link,useHistory } from "react-router-dom";

import { Bar, HorizontalBar, Pie, Doughnut } from "react-chartjs-2";

import {
  FaArrowRight,
  FaCaretDown,
  FaCaretRight,
  FaCaretUp,
} from "react-icons/fa";
import api from "../../services/api";

import convertToChartJs from "../../utils/convertToChartJs";
import moneyAbbreviation from "../../utils/moneyAbbreviation";

import showTableImg from "../../assets/Show_table.png";
import clearFilterImg from "../../assets/clear_filter.png";
import ButtonGraphGroup from "../../components/ButtonGraphGroup";

import {backgroundColors, getOptions} from '../../utils/chartUtils';

import humanize from '../../utils/humanize';
import { Field, Form, Formik } from "formik";
import Select from "../../components/Select";
import Downloadable from '../../components/Downloadable';

function Specialists() {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    currencyDisplay: "code",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  const { token } = useAuth();

  const history = useHistory();

  const [agency, setAgency] = useState("");
  const [country, setCountry] = useState("");
  const [actualData, setActualData] = useState({});

  const [reviewTypeGraph, setReviewTypeGraph] = useState([]);
  const [marketApproach, setMarketApproach] = useState([]);
  const [projectsByCountry, setProjectsByCountry] = useState([]);
  const [performanceRating, setPerformanceRating] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);
  const [projectSpecialists, setProjectSpecialists] = useState([]);

  const [graphShowType, setGraphShowType] = useState({
    projectByCountry: '#'
  });
  const [dashboard, setDashboard] = useState({});
  const [geolocation, setGeolocation] = useState([]);

  const [showFilters, setShowFilters] = useState(true);
  const [map, setMap] = useState(null);

  const [expand, setExpand] = useState('');
  

  const [projectInfo, setProjectInfo] = useState('');

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    api.get("/projectSpecialists/filterOptions").then((response) => {
      setFilterOptions(response.data);
    });

    

    loadGraph();
  }, [token]);

  function loadGraph() {
    let query = [];
    Object.keys(actualData).forEach((e) => {
      query.push(e + "=" + encodeURIComponent(actualData[e]));
    });
    query = "?" + query.join("&");
    if(query == '?'){
      query = '';
    }

    api.get('/projectSpecialists'+query).then(response => {
      setProjectSpecialists(response.data);
    });


    function littleConv(data){
      if(data != null){
        return data.map(e=>{
          return {
            label: e._id,
            value: e.total,
            total: e.total,
            projectCost: e.projectCost,
            totalAmount: e.totalAmount,
          }
        })
      }else{
        return [];
      }
    }

    api.get('/projectSpecialists/geolocation'+query).then((response)=>{
      setGeolocation(response.data);
    });
  }

  useEffect(
    function () {
      loadGraph();
    },
    [actualData]
  );


  const [mapCircles, setMapCircles] = useState({});
  const [infoWindow, setInfoWindow] = useState();


  function initMap() {

    if(infoWindow == null){
      setInfoWindow(new window.google.maps.InfoWindow({
        content: ''
      }));
    }else{
      infoWindow.close();
    }

    // Create the map.
    if(map == null){
      const map = new window.google.maps.Map(window.document.getElementById("map"), {
        zoom: 3,
        center: { lat: -16.9262801, lng: -69.3966478 },
        // mapTypeId: "terrain",
        mapId: 'af8391a6d948a37d',
        // gestureHandling: "none",
        // zoomControl: false,
      });
      setMap(map);
    }
    
    if(!map){
      return;
    }
    (map?.cts??[]).forEach((circle) => {
      remove_circle(circle);
    });

  
    map.cts = [];
    for (const city of geolocation) {
      city.geolocation.forEach(e=>{
        if(e.locationName && e.latitude && e.longitude){
          let k = e.locationName+e.latitude.toString()+e.longitude.toString();
          let latlng = {lat: e.latitude, lng: e.longitude};
          // Add the circle for this city to the map.
          const cityCircle = new window.google.maps.Circle({
            strokeColor: "#2D57C2",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#fff",
            fillOpacity: 0.6,
            map,
            center: latlng,
            radius: 150000,
            title: e.locationName,
            clickable:true
          });
  
          window.google.maps.event.addListener(cityCircle, 'mouseover', function(ev){
            infoWindow.setContent("<h5>"+cityCircle.title+"</h5>");
            infoWindow.setPosition(cityCircle.getCenter());
            infoWindow.open(map);
          });
          map.cts.push(cityCircle);
        }
      });
    }

    let bounds = new window.google.maps.LatLngBounds();
    if(map.cts.length > 0){
      map.cts.forEach(e=>{
        bounds.extend(e.getCenter());
      });
      map.fitBounds(bounds);
      let listener = window.google.maps.event.addListener(map, "idle", function () {
        if(map.getZoom() > 5){
          map.setZoom(5);
        }
        window.google.maps.event.removeListener(listener);
      });
    }

  }

  function remove_circle(circle) {
    // remove event listers
    window.google.maps.event.clearListeners(circle, 'click_handler_name');
    window.google.maps.event.clearListeners(circle, 'drag_handler_name');
    circle.setRadius(0);
    // if polygon:
    // polygon_shape.setPath([]); 
    circle.setMap(null);
  }

  let notSettedMap = true;
  useEffect(function(){
    let x = window.document.getElementById('map');

    if(x != null && window.google){
      initMap();
    }
  });


  const [active, setActive] = useState("Robert");
  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title="Specialists"
          children={
            <>
              <Formik
                    
                    >
              {
                    (props)=><Form>
              {showFilters &&
                  filterOptions &&
                  Object.entries(filterOptions)
                    .map(([key, val]) => {
                      return {
                        get: function () {
                          let c = actualData || {};
                          let r = c[key];
                          return r || "";
                        },
                        set: function (c) {
                          let d = actualData || {};
                          d[key] = c.target.value;
                          if (!d[key]) {
                            delete d[key];
                          }
                          setActualData({ ...d });
                        },
                        options: val.map((e) => {
                          return {
                            value: e?.projectId ?? e?.agencyId ?? e?.name ?? e,
                            label: e?.projectId ?? e?.name ?? e,
                          };
                        }).concat([{label:'SELECT...',value:''}]),
                        title: humanize(key),
                        selectPl: humanize(key),
                      };
                    })
                    .map((e, i) => (
                      <div key={i} className="col-12 col-md-4 my-1 mr-auto">
                        <div className=" h-100">
                          <div className=" h-100">
                            <div className="row h-100">
                              <div className="">
                                <div className="row text-center">
                                  <div
                                    className="d-flex flex-column justify-content-end "
                                    style={{ height: "60px" }}
                                  >
                                    <label style={{fontSize:'15px'}}>
                                      <b>{e.selectPl}</b>
                                    </label>
                                  </div>
                                  <div className="w-100">
                                    <Field 
                                      as={Select}
                                      className="form-select p-1"
                                      onChange={e.set}
                                      value={e.get()}
                                      options={e.options}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Form>}
              </Formik>
            </>
          }
        />

        <div className="container-fluid specialist_body" style={{}}>
          

          <div className="row text-center ">
            <div className={(expand == 'projectByCountry'?'col-11 col-md-10':'col-11 col-md-6')+" mx-auto text-center my-4 animatedContainer p-3"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className='my-auto'>Table</h5>
                    <h6 className='my-auto'>{actualData.specialist ? 'Specialist: '+actualData.specialist : ''}</h6>
                  </div>
                  <div className='col-2 col-md-1'>
                    <Downloadable
                      path={'projectSpecialists'}
                      innerGet={(function(){
                        let query = [];
                        Object.keys(actualData).forEach((e) => {
                          query.push(e + "=" + encodeURIComponent(actualData[e]));
                        });
                        return query.join('&');
                      })()}
                    />
                  </div>
                </div>
              </div>
              <div style={{
                position: 'relative',
                width: '100%'
              }} className='table-responsive'>
                  <table class='table'>
                    <thead>
                      <tr>
                        <th>Project ID</th>
                        <th>Unique ID</th>
                        <th>Project Name</th>
                        <th>Score</th>
                        <th>Perc Score</th>
                        <th>Performance Rating</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        projectSpecialists.map((e)=>{
                          return <tr>
                            <td>{e.projectId}</td>
                            <td><Link to={'/riskEvaluations?uniqueId='+encodeURIComponent(e.uniqueId)}>{e.uniqueId}</Link></td>
                            <td>{(e.projectName)}</td>
                            <td>{e.achievedScore}</td>
                            <td>{e.achievedPercentage?.toFixed(2)}</td>
                            <td>{e.performanceRating}</td>
                          </tr>
                        })
                      }
                    </tbody>
                  </table>
                
              </div>
            </div>
            <div className='col-12 col-md-6 p-3'>

                <div id='map' style={{
                  height:600,
                  // width:400,
                  margin: '0 auto'
                }}>
                  
                </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Specialists;
