import React, { useState, useEffect } from "react";

import { FaDollarSign, FaExpandAlt, FaHashtag, FaPercentage } from 'react-icons/fa';

import imgInfo from '../../assets/Information.png';

import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import {backgroundColors, getOptions} from '../../utils/chartUtils';
import { HorizontalBar } from "react-chartjs-2";

function Top100ElementsChart({
    url,
    token,
    title,
    user,
    appendQuery=true
}){

    const [specialistsRank, setSpecialistsRank] = useState([]);
    const [graphHeight, setGraphHeight] = useState(3000);


    // 3000 = 116

    useEffect(() => {
        console.log(token);
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    
        loadGraph();
    }, [token]);

    function loadGraph(){
        api.get(url + (appendQuery ? "?elements=100" : '')).then((response) => {
            let dt = response.data.sort((a,b)=>(b.total ?? b.achievedAveragePercentage) - (a.total ?? a.achievedAveragePercentage));
            setSpecialistsRank(dt);
            setGraphHeight(((dt.length * 3000) / 116));
        });
    }


    return <>
    <h1>{title}</h1>

    <div style={{
                position: 'relative',
                width: '100%',
                height:graphHeight+"px"
              }}>
        <HorizontalBar
            data={{
            labels: specialistsRank.map((e,i) => (i+1)+" - "+ (e._id == user.name ? user.name.toUpperCase() : e._id)),
            datasets: [
                {
                label: "",
                data: specialistsRank.map((e) => e.total ?? e.achievedAveragePercentage ),
                backgroundColor: backgroundColors(),
                borderWidth: 0,
                },
            ],
            }}
            options={Object.assign(getOptions(), {
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: specialistsRank.length > 0 && specialistsRank[0].achievedAveragePercentage != undefined ? {
                            // beginAtZero: true,
                            suggestedMax: 100,
                            suggestedMin: 0,

                        } : {}
                    } ],
                    yAxes: [{
                        ticks: specialistsRank.length > 0 && (specialistsRank[0].achievedAveragePercentage != undefined) ? {
                            suggestedMax: 100,
                        }: {}
                    }]
                }
            })}
        />
    </div>

    </>;
}

export default Top100ElementsChart;