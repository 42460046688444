import moneyAbbreviation from './moneyAbbreviation';

function backgroundColors(){
    return [
        // COLOR FROM LOGO
        "#114C6E",
        "#0048bc",
        "#adccff",
        "#727272",
        // COLOR FROM ANYWHERE
        "black",
        "#976945",
        "#214ed5",
        "#d249ae",
        "#cc8963",
        "#ad59b5",
        "#bd759a",
        "#d4f2d8",
        "#af1616",

        // COLOR RANDOM GENERATED
        '#a3d17b',
        '#950c92',
        '#9081e0',
        '#4747ef',
        '#aa4973', 
        '#6a2396', 
        '#66aca3', 
        '#b03bba', 
        '#7120c0', 
        '#0391b1', 
        '#861b21', 
        '#f9c525', 
        '#dbed87', 
        '#6a4f49', 
        '#51e331', 
        '#7c6deb', 
        '#84b72f', 
        '#05f1ad', 
        '#7987ba',
        '#ab5617', 
        '#9102c3',
        '#4d1a88', 
        '#80b7e8', 
        '#60ed46', 
        '#a1a820',
        "#3E5B88",
        "#68689D",
        "#9375AD",
        "#BE81B7",
        "#E78FBA",
        "#4D7DA2",
        "#D2F5FF",
        "#E69C24",
        "#E69C24",
        "#FFECCC",
        "#8B7356",
        "#9EADBD",
        "#6A7987",
        "#683850",
        "#9D6982",
        "#00C5B2",
        "#FFF7D6",
        "#A5D3FC",
        "#E69C24",
        "#95E07E",
        "#F9F871",
        "#3D8E85",
        "#7BFAC3",
        "#3CC08E",
        "#008A5B",
        "#009DF8",
        "#2CBF92",
        "#009A9B",
        "#3A4856",
        "#9EADBD",
        "#5C4321",
        "#91734F",
        "#7E2D23",
        "#00563E",
        "#3A4856",
        "#9EADBD",
        "#9EADBD",
        "#683850",
        "#BCA5AF",
        "#0084A0",
        "#00C0BD",
        "#7BFAC3",
        "#802936",
        "#24511F",
        "#00BAFF",
        "#F1F1E6",
        "#FFF7D6",
        "#00C6B3",
        "#22857B",
        "#334B48",
        "#009DF8",
        "#F2FAFF",
        "#FFF7D6",
        "#9EADBD",
        "#6A7987",
        "#9D6982",
        "#154884",
        "#7A2A56",
        "#82B0D8",
        "#E69C24",
        "#6F3A00",
        "#00BAFF",
        "#F1F1E6",
        "#E69C24",
        "#3D6D92",
        "#6391B7",
        "#88B6DE",
        "#AFDCFF",
        "#8EBCE4",
        "#114C6E",
        "#00203E",
        "#000C2A",
      ];
}

function getOptions(formatMoney = false,legend=false) {
  let options = {};
  let x = {
    legend: {
      display: legend
    },
    responsive: true,
    maintainAspectRatio: true,
  };
  x.tooltips = {
    callbacks: {
      label: function (tooltipItem, data) {
        let d =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        return d;
      },
    },
  };
  if (formatMoney) {
    x.tooltips = {
      callbacks: {
        label: function (tooltipItem, data) {
          let d =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return moneyAbbreviation(d);
        },
      },
    };

    x.scales = {
      yAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return " "+moneyAbbreviation(value)+" ";
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return " "+moneyAbbreviation(value)+" ";
            },
          },
        },
      ],
    };
  } else {
    x.scales = {
      yAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value;
            },
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
            // Include a dollar sign in the ticks
            callback: function (value, index, values) {
              return value;
            },
          },
        },
      ],
    };
  }
  return { ...Object.assign(options, x) };
}

export {
    backgroundColors,
    getOptions
} 