import React  from 'react';

import {
  TextAreaBlock,
  Label,
  InputTextArea
} from './styles';

function TextArea({ label, name, ...rest }) {
  return (
    <TextAreaBlock>
      <Label htmlFor={name}>{label}</Label>
      <InputTextArea id={name}  {...rest} style={
        Object.assign({ background:'white' },(rest.style ?? {}))
      }  />
    </TextAreaBlock>
  );
}

export default TextArea;