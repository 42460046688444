import React, { useState, useEffect } from "react";
import SideBar from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";
import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import Input from "../../components/Input";
import { Field, Form, Formik } from "formik";
import { ButtonBack, ButtonSubmit, Subtitle } from "../../components/Form/styles";
import { Button, ButtonPrimary } from "../ForgotPassword/styles";
import AgencyForm from "../Agency/form";
import ProjectForm from "../Project/form";
import RiskForm from "../Risk/form";
import SpecialistForm from "../Specialist/form";
import { LocalDining } from "@material-ui/icons";
import {useParams} from 'react-router';
import FixedCalcInfo from "./fixedCalcInfo";

function AllForm() {
  const { token, user } = useAuth();
  const history = useHistory();

  if(user.profile == 'ReadOnly'){
    history.replace('/landing');
  }

  const { draftId } = useParams();

  const [agency, setAgency] = useState('0000');
  const [agencyName, setAgencyName] = useState('');
  const [projectInfo, setProjectInfo] = useState({
    project:'',
    country:'',
    projectName:''
  });
  const [draft, setDraft] = useState('');

  const [projectRef, setProjectRef] = useState();
  const [agencyRef, setAgencyRef] = useState();

  // const

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    load();
  }, [token]);

  function load(){
    if(draftId){
      api.get('/riskAssessments/drafts/'+draftId).then((e)=>{
        setDraft(e.data);
        api.get('/riskAssessments/selectOptions').then((pr)=>{
          let projects = pr.data.project;
          let projectName = ''
          let agencyName = '';
  
          if(e.data.projectId){
            projectName = projects.find((c)=>c?.projectId == e.data.projectId)?.name;
          }
          if(e.data.agencyId){
            if(e.data.agencyId == '0000'){
              agencyName = 'NO IMPLEMENTATION STRUCTURE EXISTS FOR THE PROJECT IN STEP';
            }else{
              agencyName = pr.data.agency.find((c)=>c.agencyId == e.data.agencyId)?.name;  
            }
          }
          setProjectInfo({project:e.data.projectId, country: e.data.country, projectName: projectName});
          setAgencyName(agencyName);
          setAgency(e.data.agencyId);
        });
      }).catch((e)=>{
        setDraft('');
        setProjectInfo({project:'', country: '', projectName:''});
        setAgency('');
      });
    }
  }

  useEffect(()=>{
    if(projectRef?.current){
      projectRef.current.setFieldValue('projectId', projectInfo.project);
    }
  },[projectInfo,projectRef]);

  useEffect(()=>{
    if(agencyRef?.current){
      agencyRef.current.setFieldValue('agencyId', agency);
    }
  },[agency,agencyRef]);

  var onSubmitClicked = async (agency, project, country)=>{

  };

  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title="Form"
          fixedChildLayout={true}
        />
        <div className="container">
          <div className="row ">
            <div className='col m-3'>
                
                <Subtitle>
                    Project
                </Subtitle>
                <ProjectForm 
                  project={projectInfo.project ?? ''}
                  country={projectInfo.country ?? ''}
                  agency={agency}
                  innerRef={(ref)=>{
                    setProjectRef(ref);
                    
                  }}
                  onChange={(e,c,d)=>{
                    setProjectInfo({project:e, country:c, projectName:d});
                  }}
                />

                <Subtitle>
                    Agency
                </Subtitle>
                <AgencyForm 
                  agency={agency ?? ''}
                  innerRef={(e)=>{
                    setAgencyRef(e);
                  }}
                  country={projectInfo.country}
                  project={projectInfo.project}
                  onChange={(e,b)=>{
                    setAgency(e);
                    setAgencyName(b);
                  }}
                />

                

                <Subtitle>
                    Risk
                </Subtitle>
                <RiskForm 
                  draft={draft}
                  projectRef={projectRef}
                  agencyRef={agencyRef}
                  country={projectInfo.country} 
                  projectName={projectInfo.projectName}
                  agencyName={agencyName}
                  setErrors={()=>{
                    // setHasErrors(true);
                  }}
                  callback={(e)=>{
                  onSubmitClicked = e;
                }} />

                <div className=" mt-5 btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                    <div className='btn-toolbar'>
                        <div className='btn-group me-2'>
                            <ButtonPrimary 
                            onClick={(e)=>{
                              onSubmitClicked(agency, projectInfo.project, projectInfo.country).then((e)=>{
                                load();
                              });;
                            }}
                            type="submit" 
                            className='btn btn-primary' >Submit</ButtonPrimary>
                        </div>
                        <div className='btn-group'>
                            <ButtonPrimary 
                            type="submit" className='btn btn-primary' onClick={()=>{
                              onSubmitClicked(agency, projectInfo.project, projectInfo.country, true).then((e)=>{
                                load();
                              });
                            }}>Save as Draft</ButtonPrimary>
                        </div>
                    </div>
                    <div className="">
                        <ButtonPrimary type="button" className='btn btn-primary' onClick={()=>{
                            history.goBack();
                        }}>Cancel</ButtonPrimary>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default AllForm;
