import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export default function Downloadable({innerGet, path, enablePdf=false}){
    const { token } = useAuth();
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      }, [token]);

    async function handleError(data){
        let f = JSON.parse(await data.text());
        MySwal.fire('Ops!',f.message,'error');
    }

    function download(format){
        api({
            url: path+'/download?format='+format+'&'+innerGet, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            if(response.status == 200){
                let f = response.headers['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1]:null;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                let format = response.headers['content-type'].includes('spreadsheet') ? 'xlsx':'pdf';
                link.setAttribute('download', f ? f: path+"."+format); //or any other extension
                document.body.appendChild(link);
                link.click();
            }else{
                if(response && response.data){
                    handleError(response.data);
                }
            }
        }).catch((e)=>{
            if(e.response && e.response.data){
                handleError(e.response.data);
            }
        });
    }

    return <>
        <button
            className="btn btn-outline-link"
            title="Download .xlsx"
            onClick={() => {
                download('xlsx');
            }}
        >
            <FaFileExcel size={25} color={'green'} />
        </button>
        {
            enablePdf &&
            <button
                className="btn btn-outline-link"
                title="Download .pdf"
                onClick={() => {
                    download('pdf');
                }}
            >
                <FaFilePdf size={25} color={'red'} />
                {/* <img src={showTableImg} style={{ width: "20px" }}></img> */}
            </button>
        }
    </>;
};