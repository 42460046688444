import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import { Link, useHistory } from "react-router-dom";
import {
  FaSignOutAlt,
  FaUserMd,
  FaMicroscope,
  FaPills,
  FaTasks,
  FaList,
  FaChartBar,
  FaNotesMedical,
  FaArrowLeft,
  FaArrowRight,
  FaChartPie,
  FaChalkboard,
  FaUserTag,
  FaDesktop,
  FaHome,
} from "react-icons/fa";

import { useAuth } from "../../hooks/auth";

import { ButtonCircle } from "../Form/styles";

import logo from "../../assets/calitool.png";
import logoIcon from "../../assets/calitool_only_icon.png";
import activity from "../../assets/activity.png";
import contractAmendment from "../../assets/contract_amendment.png";
import contract from "../../assets/contract.png";
import logoutIcon from "../../assets/logout.png";
import roadmaps from "../../assets/roadmaps.png";
import Settings from "../../assets/Settings.png";

import useWindowDimensions from "../../hooks/dimension";

const Aside = ({ toggled, handleToggleSidebar }) => {
  const { signOut, user } = useAuth();
  const history = useHistory();

  const [collapsed, setCollapsed] = useState(localStorage.getItem('collapseState') == 'true');

  function logout() {
    localStorage.removeItem("@calitool:exec_studyToken");
    localStorage.removeItem("@calitool:studyToken");
    localStorage.removeItem("notificationAlert");
    signOut();
  }

  const { height, width } = useWindowDimensions();

  return (
    <div className="row">
      <div className="col-12" style={{ margin: 0, padding: 0 }}>
        <ProSidebar
          breakPoint="md"
          toggled={toggled}
          onToggle={(v) => {
            handleToggleSidebar(v);
            setCollapsed(v);
            localStorage.setItem('collapseState', v);
          }}
          collapsed={width > 768 ? collapsed : toggled && collapsed}
          collapsedWidth={100}
        >
          <SidebarHeader style={{
            backgroundColor: '#F7F8FC'
          }}>
            <div className="row">
              <div className="col-12">
                <div style={{ display: "inline-block" }}>
                  <div
                    style={{
                      paddingLeft: "24px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: 24,
                      letterSpacing: "1px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      display: "inline-block",
                      height:70
                    }}
                  >
                    <Link
                      to="/landing"
                      style={{
                        marginRight: "40px",
                      }}
                    >
                      <img src={logoIcon} width="50" style={{
                        margin:'15px 15px 10px 8px',
                        visibility: collapsed ? 'visible':'hidden',
                        opacity: collapsed ? 1:0,
                        transition: 'visibility 0s, opacity 0.5s linear'
                        }}></img>
                      <img src={logo} width="200" style={{
                        visibility: !collapsed ? 'visible':'hidden',
                        opacity: !collapsed ? 1:0,
                        transition: 'visibility 0s, opacity 0.5s linear',
                        position: 'absolute',
                        left:'30px',
                        top: '20px'
                      }}></img>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SidebarHeader>

          <SidebarContent style={{
            color:'white',
            backgroundColor: 'var(--color-primary)'
            }}>
            <Menu iconShape="circle">
              <MenuItem
                style={{
                  paddingLeft: 15,
                }}
                icon={
                  collapsed ? (
                    <FaArrowRight size={18} />
                  ) : (
                    <FaArrowLeft size={18} />
                  )
                }
              >
                {collapsed ? "Expand" : "Collapse"}
                <Link
                  to="#"
                  onClick={() => {
                    setCollapsed(!collapsed);
                    localStorage.setItem('collapseState', !collapsed);
                  }}
                />
              </MenuItem>

              <MenuItem
                style={{
                  paddingLeft: 15,
                }}
                icon={  <FaHome size={26} /> }
                active={window.location.pathname.startsWith("/landing")}
              >
                Home
                <Link to="/landing" />
              </MenuItem>

              <MenuItem
                style={{
                  paddingLeft: 15,
                }}
                icon={  <FaChartPie size={26} /> }
                active={window.location.pathname.startsWith("/projectMetrics")}
              >
                Project Metrics
                <Link to="/projectMetrics" />
              </MenuItem>

              <MenuItem
                style={{
                  paddingLeft: 15,
                }}
                icon={<FaChalkboard size={26} /> }
                active={window.location.pathname.startsWith("/riskEvaluations")}
              >
                Risk Evaluation
                <Link to="/riskEvaluations" />
              </MenuItem>

              <MenuItem
                style={{
                  paddingLeft: 15,
                }}
                icon={<FaUserTag size={26} /> }
                active={window.location.pathname.startsWith("/specialists")}
              >
                Specialists
                <Link to="/specialists" />
              </MenuItem>

              {
                user.profile != 'ReadOnly' &&
                <SubMenu
                  style={{
                    paddingLeft: 15,
                  }}
                  title="Form"
                  icon={<FaList size={18} />}
                  defaultOpen={["/form"].some((e) =>
                    window.location.pathname.startsWith(e)
                  )}
                >
                  <MenuItem
                    style={{
                      paddingLeft: 15,
                    }}
                    icon={<FaUserTag size={26} /> }
                    active={window.location.pathname.startsWith("/form/drafts")}
                  >
                    Your drafts
                    <Link to="/form/drafts" />
                  </MenuItem>
                  <MenuItem
                    style={{
                      paddingLeft: 15,
                    }}
                    icon={<FaUserTag size={26} /> }
                    active={window.location.pathname.startsWith("/form/new")}
                  >
                    New form
                    <Link to="/form/new" />
                  </MenuItem>
                </SubMenu>
              }


              {
                user.profile == 'Administrator'
                &&
                <MenuItem
                  style={{
                    paddingLeft: 15,
                  }}
                  icon={ <FaDesktop size={26} /> }
                >
                  Admin Panel
                  <Link to="/admin/dashboard" />
                </MenuItem>
              }


              <MenuItem
                style={{
                  paddingLeft: 15,
                }}
                icon={ <FaSignOutAlt size={26} /> }
              >
                Logout
                <Link to="#" onClick={logout} />
              </MenuItem>
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    </div>
  );
};

export default Aside;
