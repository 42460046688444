import axios from "axios";

const api = axios.create({
  //  baseURL: 'http://localhost:3003/',
  baseURL: process.env.REACT_APP_BACKEND_URL || "http://localhost:3003/",
  // baseURL: process.env.BACKEND_URL ||'http://localhost:3003/'
  // headers: { Authorization: `Bearer ${localStorage.getItem('@calitool:token')}` }
});

export default api;
