import React, { useState, useEffect } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import { Bar, HorizontalBar, Pie, Doughnut } from "react-chartjs-2";

import {
  FaArrowRight,
  FaBullseye,
  FaCaretDown,
  FaCaretRight,
  FaCaretUp,
  FaEraser,
  FaFilter,
} from "react-icons/fa";
import api from "../../services/api";

import convertToChartJs from "../../utils/convertToChartJs";
import moneyAbbreviation from "../../utils/moneyAbbreviation";

import showTableImg from "../../assets/Show_table.png";
import clearFilterImg from "../../assets/clear_filter.png";
import ButtonGraphGroup from "../../components/ButtonGraphGroup";

import {backgroundColors, getOptions} from '../../utils/chartUtils';

import humanize from '../../utils/humanize';
import SAMPLE_DATA_CL from "../../seed/demoData";
import { Field, Form, Formik } from "formik";
import Select from "../../components/Select";
import Downloadable from "../../components/Downloadable";

function RiskEvaluation() {
  const SAMPLE_DATA = SAMPLE_DATA_CL("Risk Evaluation");
  var formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    currencyDisplay: "code",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  const { token } = useAuth();

  const history = useHistory();

  
  let d = {};
  for (let [i, x] of window.location.search
    .replaceAll("?", "")
    .split("&")
    .map((e) => e.split("="))
    .entries()) {
    if (x[0] && x[1]) {
      d[x[0]] = (decodeURIComponent(x[1]));
    }
  }
  const [actualData, setActualData] = useState(d);

  const [projectProgress, setProjectProgress] = useState([]);
  const [riskEvaluation, setRiskEvaluation] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);

  const [graphShowType, setGraphShowType] = useState({});
  const [dashboard, setDashboard] = useState({});

  const [showFilters, setShowFilters] = useState(true);

  const [expand, setExpand] = useState('');
  const [projectInfo, setProjectInfo] = useState('');

  

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    
    // api.get("/riskAssessments/filterOptions").then((response) => {
    //   setFilterOptions(response.data);
    // });

    

    // api.get('/activities').then(response => {

    // })

    // function settfy(l){
    //   return Array.from(new Set(l));
    // }
    // setFilterOptions({
    //   // countries: settfy(SAMPLE_DATA.map(e=>e.countries)),
    //   // performanceRatingNumber: settfy(SAMPLE_DATA.map(e=>e.performanceRatingNumber)),
    //   projects: settfy(SAMPLE_DATA.map(e=>e.projects)),
    // })

    loadGraph();
  }, [token]);

  function loadGraph() {
    let query = [];
    Object.keys(actualData).forEach((e) => {
      query.push(e + "=" + encodeURIComponent(actualData[e]));
    });
    query = "?" + query.join("&");
    if(query == '?'){
      query = '';
    }

    

    api.get("/riskAssessments/filterOptions"+query).then((response) => {
      setFilterOptions(response.data);
      let ac = {...actualData};
      if(response.data.agencyName.length == 1){
        ac.agencyName = response.data.agencyName[0];
      }
      if(response.data.projectName.length == 1){
        ac.projectName = response.data.projectName[0];
      }
      if(response.data.assessmentDate.length == 1){
        ac.assessmentDate = response.data.assessmentDate[0];
      }
      if(ac.assessmentDate != actualData.assessmentDate ||
          ac.projectName != actualData.projectName ||
          ac.agencyName != actualData.agencyName){
            setActualData(ac);
          }

      setProjectInfo(ac.projectName)
    });

    api.get("/riskAssessments/dashboard"+query).then((response) => {
      setDashboard(response.data);
    }).catch((e)=>{
      setDashboard({});
    });

    if(Object.keys(actualData).length < 3){
      return;
    }

    api.get('/riskAssessments'+query).then(response => {
      setRiskEvaluation(response.data);
    }).catch((e)=>{
      setRiskEvaluation([]);
    })

    
  }

  useEffect(
    function () {
      
      loadGraph();
    },
    [actualData]
  );
  function initMap() {
    const citymap = {
      BRAZIL: {
        lat: 13.660596, lng: -69.674606
      },
      USA: {
        lat:36.2474412, lng: -113.7152476
      },
      CHINA: { 
        lat: 34.452041, lng: 86.0566493
      },
      JAPAN: {
        lat: 32.6945288, lng: 129.4216586
      },
    };
    // Create the map.
    const map = new window.google.maps.Map(window.document.getElementById("map"), {
      zoom: 4,
      center: { lat: 37.09, lng: -95.712 },
      // mapTypeId: "terrain",
      mapId: 'af8391a6d948a37d'
    });
  
    // Construct the circle for each value in citymap.
    // Note: We scale the area of the circle based on the population.
    let paises =
      applyFilter(SAMPLE_DATA).reduce(function (rv, x) {
        (rv[x["countries"]] = rv[x["countries"]] || []).push(x);
        return rv;
      }, {})
    ;
    for (const city of Object.keys(paises)) {
      // Add the circle for this city to the map.
      const cityCircle = new window.google.maps.Circle({
        strokeColor: "#2D57C2",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#114C6E",
        fillOpacity: 0.35,
        map,
        center: citymap[city],
        radius: paises[city].length * 8000,
        title: city,
        clickable:true
      });

      var infoWindow = new window.google.maps.InfoWindow({
        content: 'test'
      });
      

      window.google.maps.event.addListener(cityCircle, 'mouseover', function(ev){
        infoWindow.setContent("<h3>"+cityCircle.title+"</h3>");
        infoWindow.setPosition(cityCircle.getCenter());
        infoWindow.open(map);
      });
    }
  }
  let notSettedMap = true;
  useEffect(function(){
    let x = window.document.getElementById('map');

    if(x != null && notSettedMap && window.google){
      notSettedMap = false;
      initMap();
    }
  });


  function applyFilter() {
    return SAMPLE_DATA.filter((e) => {
      return (
        actualData.length == 0 ||
        Object.entries(actualData).every(([c, v]) => {
          return v == e[c];
        })
      );
    });
  }


  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title="Risk Evaluation"
          fixedChildLayout={true}
          children={
            <h4 style={{marginTop:10}}>{projectInfo}</h4>
          }
        />

        <div className='row px-5 mx-5'>

        {
          Object.entries(dashboard).map(([e,v], i) => (
            <div key={i} className="col-6 col-md-3">
              <div className="h-100 py-1">
                <div className="h-100">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row ">
                        <div className="col">
                          <small>{humanize(e)}</small>
                          <br></br>
                          <div className='float-right text-end'>
                          <h6>{isNaN(v)?e=="assessmentDate"?v.split("T")[0]:v:moneyAbbreviation(Number(Number(v).toFixed(2)))}
                          </h6>
                          </div>
                          {/* </div>
                      <div className="col-6 text-end"> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        </div>
        

        <div className="container-fluid">
          <div className="row mx-5">
            <div className="col-12 text-center">
              {/* {showFilters && (
                <FaCaretDown
                  size={30}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowFilters(false);
                  }}
                />
              )}
              {!showFilters && (
                <FaCaretUp
                  size={30}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowFilters(true);
                  }}
                />
              )} */}
            </div>
            <div className='col-11 text-center'>
              <Formik
                    
                    >
              {
                    (props)=><Form>
              <div className='row mx-5 text-center'>
                {showFilters &&
                  filterOptions &&
                  Object.entries(filterOptions)
                    .map(([key, val]) => {
                      return {
                        get: function () {
                          let c = actualData || {};
                          let r = c[key];
                          return r || "";
                        },
                        set: function (c) {
                          let d = actualData || {};
                          delete d['projectId'];
                          d[key] = c.target.value;
                          if (!d[key]) {
                            delete d[key];
                          }
                          setActualData({ ...d });
                          window.history.pushState({}, document.title, "/riskEvaluations");
                        },
                        options: val.map((e) => {
                          return {
                            value: e?.projectId ?? e?.agencyId ?? e?.name ?? e,
                            label: e?.name ?? e?.projectName ??  e?.projectId ?? e,
                          };
                        }).concat([{label:'SELECT...',value:''}]),
                        title: humanize(key),
                        selectPl: humanize(key),
                      };
                    })
                    .map((e, i) => (
                      <div key={i} className="col-10 col-md-4 col-xl-2 my-1 mx-auto">
                        <div className=" h-100">
                          <div className=" h-100">
                            <div className="row h-100">
                              <div className="">
                                <div className="row text-center">
                                  <div
                                    className="d-flex flex-column justify-content-end "
                                    style={{ height: "60px" }}
                                  >
                                    <label style={{fontSize:'15px'}}>
                                      <b>{e.selectPl}</b>
                                    </label>
                                  </div>
                                  <div className="w-100">
                                    <Field 
                                        as={Select}
                                        className={"form-select p-1 "+e.get()}
                                        onChange={e.set}
                                        value={e.get()}
                                        options={e.options}
                                      />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>

                    </Form>
                  }
                </Formik>
            </div>
            {showFilters &&
              <div className="col-sm-1 d-flex align-self-center my-auto">
                <div className="row ">
                  <div className="col my-1 ">
                    <button
                      className="btn  btn-outline-link "
                      title="Clear filters"
                      onClick={() => {
                        setActualData({});
                      }}
                    >
                      <FaEraser size={25} />
                    </button>
                  </div>
                  <div className="col my-1">
                    <Downloadable
                      path={'riskAssessments'}
                      enablePdf={true}
                      innerGet={(function(){
                        let query = [];
                        Object.keys(actualData).forEach((e) => {
                          query.push(e + "=" + encodeURIComponent(actualData[e]));
                        });
                        return query.join('&');
                      })()}
                    />
                  </div>
                </div>
              </div>
            }
          </div>


          <div className='row px-5 mx-5'>
              <div className="col-3 mt-5 text-center mx-auto">
                <div className="h-100 py-1">
                  <div className="h-100">
                    <div className=" ">
                      <div className="" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        
                        {/* <div className="row text-center">
                          <div className="col">
                            <div class='card shadow'>
                              <div class='card-body'> */}
                                {/* <h5>Score / Possible Points</h5> */}
                                {/* <Doughnut
                                  // width={200}
                                  // height={200}
                                  data={(canvas)=>{
                                    canvas.style.position = 'relative';
                                    canvas.style.top = '30px';
                                    return {
                                      labels: ['T','A','B'],
                                      datasets: [
                                        {
                                          label: "LEGEND",
                                          data: [50, ((projectProgress.score*50.0)/projectProgress.possiblePoints), 50 - ((projectProgress.score*50.0)/projectProgress.possiblePoints)],
                                          backgroundColor: ["#FFFFFF00", backgroundColors()[0], '#ddd'],
                                          borderWidth: 0,
                                        },
                                      ],
                                    };
                                  }}
                                  options={Object.assign(getOptions(graphShowType.procurementCategory == "$"), {
                                    rotation: (-0.5 * Math.PI) - (270/180 * Math.PI),
                                    // maintainAspectRatio: false,
                                    title: {
                                      display: true,
                                      fontSize: 15,
                                      text: isNaN(projectProgress.score) ? projectProgress.score??'' :projectProgress.score+" / "+projectProgress.possiblePoints
                                    },
                                    scales: {
                                        xAxes: [{
                                            gridLines: {
                                                color: "rgba(0, 0, 0, 0)",
                                                drawBorder: false,
                                                display: false
                                            },
                                            ticks: {
                                              display: false
                                            },
                                        }],
                                        yAxes: [{
                                            gridLines: {
                                                color: "rgba(0, 0, 0, 0)",
                                                drawBorder: false,
                                                display: false
                                            }   ,
                                            ticks: {
                                              display: false
                                            },
                                            
                                        }]
                                    },
                                    legend: {
                                      display: false
                                    },
                                    tooltips: {
                                        enabled: false,
                                        callbacks: {
                                          label: function(tooltipItem) {
                                                  return tooltipItem.yLabel;
                                          }
                                        }
                                    }

                                  })}
                                /> */}
                              {/* </div>
                            </div> */}
                            {/* </div>
                        <div className="col-6 text-end"> */}
                          {/* </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-1'>

              </div>
        </div>

          <div className='row ms-5 mt-5'>

            <div className='col'>
              <div className='card shadow'>
                <div className='card-body'>
                  <div class='text-center'>
                    {
                      actualData.projectName != null && riskEvaluation.length > 0 ?  <h5>ProjectId: {riskEvaluation[0].projectId}</h5> : <></>
                    }
                  </div>
                  <div className='table-responsive'>

                  {
                  Object.keys(actualData).length < 3 ?
                  <div class='text-center'><h3>Please fill all the filters</h3></div>
                  :

                    <table className='table' style={{'border-collapse': 'collapse'}}>
                      <thead>
                        <tr>
                          <th style={actualData.projectName == null ? {} : {display:'none'} }>Project ID</th>
                          <th>Reference</th>
                          <th>ID Risk</th>
                          <th>Risk Factor Rating</th>
                          <th>C/D</th>
                          <th>Y/N</th>
                          <th>T</th>
                          <th>Score</th>
                          <th>Action Plan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          riskEvaluation.map((e,i)=>(
                            <>
                            {
                              e.assessmentId.split('.')[0] != 'A' && Number(e.assessmentId.split('.')[1]) == 1 && 
                              <tr style={{borderBottom: '2px solid black'}}>
                                {/* <td colspan={9}></td> */}
                              </tr>
                            }
                            <tr key={i}>
                              <td style={actualData.projectName == null ? {} : {display:'none'} }>{e.projectId}</td>
                              <td>{e.riskFactor}</td>
                              <td>{e.assessmentId}</td>
                              <td>{e.riskFactorRating}</td>
                              <td>{e['compulsoryOrDesirable']}</td>
                              <td>{e['yOrN']}</td>
                              <td>{e.total}</td>
                              <td>{e.score}</td>
                              <td>{e.actionPlan}</td>
                            </tr>
                            </>
                          ))
                        }
                      </tbody>
                    </table>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </Container>
    </>
  );
}

export default RiskEvaluation;
