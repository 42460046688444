import React, { useState, useEffect, useRef } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import api from "../../services/api";

import Input from "../../components/Input";
import Select from "../../components/Select";
import { Field, Form, Formik } from "formik";
import { ButtonBack, ButtonSubmit } from "../../components/Form/styles";
import { Button, ButtonPrimary } from "../ForgotPassword/styles";
import sortFilters from "../../utils/sortFilters";
import * as Yup from 'yup';
import AsyncLock from 'async-lock';

function ProjectForm({project, country,innerRef,agency,onChange=(a,b,d)=>{}}) {
  const lock = new AsyncLock()
  const { token } = useAuth();
  const history = useHistory();

  const [projects, setProjects] = useState([]);
  const [countries, setCountries] = useState([]);
  const [actualData, setActualData] = useState({
    projectId: project ?? undefined,
    country: country ?? undefined
  });

  const [loading, setLoading] = useState(false);
  
  useEffect(()=>{
    console.log("PRIJECT UPDATE", project);
    let n = actualData;
    if(actualData.projectId != project){
      n.projectId = project;
    }
    if(actualData.country != country){
      n.country = country;
    }
    if(actualData.agency != agency){
      n.agencyId = agency == '0000' ? undefined : agency;
    }
    setActualData({...n})
  },[project,country,agency]);

  const ref = useRef();
  if(innerRef){
    innerRef(ref);
  }

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    load();
  }, [token]);

  function load(){
    lock.acquire('loadProjects', async function (release) { 
      setLoading(true);

      let query = [];
      Object.keys(actualData).forEach((e) => {
        if(actualData[e]){
          query.push(e + "=" + actualData[e]);
        }
      });
      query = "?" + query.join("&");
      if(query == '?'){
        query = '';
      }

      await api.get('/riskAssessments/selectOptions'+query).then((e)=>{
        let pr = sortFilters({projects:e.data.project}).projects;
        setProjects(pr);
        setCountries(sortFilters({countries:e.data.country}).countries);

        if(e.data.country.length == 1){
          ref.current.setFieldValue('country', e.data.country[0]);
          if(!pr.find((e)=>e.projectId == project)){
            onChange('',e.data.country[0],'');
          }
        }

        release();
        setLoading(false);
      });
    });
    
  }

  useEffect(load, [actualData]);

  return (
    <>
        <div className="container">
          <div className="row ">
            <div className='col p-4'>
                <Formik
                    onSubmit={(values) => {}}
                    validateOnMount
                    innerRef={ref}
                    validationSchema={
                      Yup.object({
                        country: Yup.string()
                          .required('Country is required'),
                        projectId: Yup.string()
                          .required('Project Id is required'),
                      })
                    }
                    initialValues={{
                        country:country,
                        projectId:project
                    }}
                    >
                    {
                        (props)=><Form>


                        <Field
                            as={Select}
                            name='country'
                            label='Country'
                            isDisabled={loading}
                            className={props.errors.country && props.touched.country ? 'text-danger' : ''}
                            options={(countries.concat([''])).map((e)=>({label:!e?'SELECT...':e,value:e}))}
                            onChange={(e)=>{
                              let d = actualData || {};
                              d['country'] = e.target.value;
                              if (!d['country']) {
                                delete d['country'];
                              }
                              setActualData({ ...d });
                              props.setFieldValue('country', e.target.value);
                              onChange(props.values.projectId,e.target.value, projects.find((e)=>e.projectId == props.values.projectId)?.name);
                              
                            }}
                        />
                        {props.errors.country && props.touched.country ? (
                          <div className='text-danger'>{props.errors.country}</div>
                        ) : null}

                        <Field
                            as={Select}
                            name='projectId'
                            isDisabled={loading}
                            className={props.errors.projectId && props.touched.projectId ? 'text-danger' : ''}
                            label='Project Name'
                            onChange={(e)=>{
                              props.setFieldValue('projectId', e.target.value);
                              let d = actualData || {};
                              d['projectId'] = encodeURIComponent(e.target.value);
                              if (!d['projectId']) {
                                delete d['projectId'];
                              }
                              setActualData({ ...d });
                              onChange(e.target.value,props.values.country,projects.find((c)=>c.projectId == e.target.value)?.name);
                            }}
                            options={(projects.concat([{name:'SELECT...',projectId:''}])).map((e)=>({label:e.name, value: e.projectId}))}
                        />

                        {props.errors.projectId && props.touched.projectId ? (
                          <div className='text-danger'>{props.errors.projectId}</div>
                        ) : null}

                        


                    </Form>
                    }
                </Formik>
            </div>
          </div>
        </div>
    </>
  );
}

export default ProjectForm;
