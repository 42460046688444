import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import Landing from "../pages/Landing";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import NoMatch from "../pages/NoMatch";

import ProjectMetrics from "../pages/ProjectMetrics";
import Showtable from "../pages/Showtable";
import ShowtableProd from "../pages/Showtable/prod";

import RiskEvaluation from "../pages/RiskEvaluation";
import Specialists from "../pages/Specialists";
import AgencyForm from "../pages/Agency/form";
import ProjectForm from "../pages/Project/form";
import RiskForm from "../pages/Risk/form";
import SpecialistForm from "../pages/Specialist/form";
import AllForm from "../pages/Form";
import FormDrafts from "../pages/Form/drafts";
import AdminDashboard from "../pages/Admin/Dashboard/index";
import AdminCardDashboard from "../pages/Admin/Dashboard/card";
import AdminActionPlansDashboard from '../pages/Admin/Dashboard/actionPlans';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" exact component={SignUp} />
    <Route path="/recoverPassword" exact component={ForgotPassword} />
    <Route path="/landing" exact component={Landing} isPrivate />

    <Route path="/:id/showTable/prod" exact  component={ShowtableProd} isPrivate />
    <Route path="/:id/showTable" exact component={Showtable} isPrivate />

    <Route path="/projectMetrics" exact component={ProjectMetrics} isPrivate />
    <Route path="/riskEvaluations"  exact component={RiskEvaluation} isPrivate />
    <Route path="/specialists" exact component={Specialists} isPrivate />
    <Route path="/agencies/new" exact component={AgencyForm} isPrivate />
    <Route path="/projects/new" exact component={ProjectForm} isPrivate />
    <Route path="/risks/new" exact component={RiskForm} isPrivate />
    <Route path="/specialist/new" exact component={SpecialistForm} isPrivate />
    <Route path="/form/new" exact component={AllForm} isPrivate />
    <Route path="/form/drafts" exact component={FormDrafts} isPrivate />
    <Route path="/form/:draftId" exact component={AllForm} isPrivate />
    <Route path="/admin/dashboard" exact component={AdminDashboard} isPrivate />
    <Route path="/admin/dashboard/detail" exact component={AdminCardDashboard} isPrivate />
    <Route path="/admin/dashboard/actionPlans" exact component={AdminActionPlansDashboard} isPrivate />

    <Route path="*" component={NoMatch} />
  </Switch>
);

export default Routes;
