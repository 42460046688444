import React, { useState, useEffect, useRef } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import api from "../../services/api";

import Input from "../../components/Input";
import Select from "../../components/Select";
import TextArea from "../../components/TextArea";
import { Field, Form, Formik } from "formik";
import { ButtonBack, ButtonSubmit } from "../../components/Form/styles";
import { Button, ButtonPrimary } from "../ForgotPassword/styles";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import sortFilters from "../../utils/sortFilters";
import { RefreshOutlined } from "@material-ui/icons";
import * as Yup from 'yup';
import {
  FaInfoCircle,
} from "react-icons/fa";
import FixedCalcInfo from "../Form/fixedCalcInfo";

function RiskForm({draft,projectRef,agencyRef,country, projectName,agencyName, callback=()=>{}, setErrors=()=>{}}) {
  const { token } = useAuth();
  const history = useHistory();

  callback(submit);

  const [specialists, setSpecialists] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [projects, setProjects] = useState([]);
  const [meta, setMeta] = useState({
  });

  const [optionsActionPlan, setOptionsActionPlan] = useState([]);
  const MySwal = withReactContent(Swal);
  const ref = useRef();
  const cdtitle = "C = Compulsory (mandatory)<br/>D = Desirable";
  
  const [tableData, setTableData] = useState([
    {
      header: [
        'Reference',
        'No.',
        'A',
        'PROCUREMENT REGULATORY FRAMEWORK AND MANAGEMENT CAPABILITY',
        
        <>C/D <span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={cdtitle}><FaInfoCircle  /></span></>,
        'Y/N',
        'T',	
        'SCORE',	
        'Outline of the Action Plan for Improvement of Areas of Opportunity identified',
      ],
      left: 'PROCUREMENT REGULATORY FRAMEWORK AND MANAGEMENT CAPABILITY',
      rows:[
        [
          {
            type:'number',
            min:0,
            value:1,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'A.1',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'The Client (or Agency) follows national procurement laws and national and/or internal procedures that are well documented and publicly available',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px',
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:2,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'A.2',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'The Client (or Agency)\'s procurement regulations generally meet the Core Procurement Principles of value for money, economy, efficiency, effectiveness, integrity, transparency, fairness and accountability',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            },
            
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
        ],
        [
          {
            type:'number',
            min:0,
            value:3,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'A.3',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The Client regulatory system as defined “on paper” is effectively applied consistently "in practice"',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:4,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'A.4',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'The Agency has a clear system of accountability with clearly defined responsibilities and delegation of authority on who has control of Procurement decisions',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:5,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'A.5',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'Project implementation and Project appropriations are typically not delayed in approving or diverted and there is predictability of funds availability for implementation',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:6,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'A.6',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Realistic Procurement plans aligned with the budget are prepared routinely in the Agency, approved formally and followed during implementation.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:7,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'A.7',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The Agency has sufficient staff with the right skills and behaviors and a satisfactory track record in carrying out Procurement.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ]
      ]
    },
    {
      header: [
        'Reference',
        'No.',
        'A',
        'INTEGRITY AND OVERSIGHT',
        
        <>C/D <span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={cdtitle}><FaInfoCircle  /></span></>,
        'Y/N',
        'T',	
        'SCORE',	
        'Outline of the Action Plan for Improvement of Areas of Opportunity identified',
      ],
      left: 'INTEGRITY AND OVERSIGHT',
      rows:[
        [
          {
            type:'number',
            min:0,
            value:8,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'B.1',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'There is adequate internal and external oversight of procurement that provides timely and regular feedback e.g. through procurement audits.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:9,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'B.2',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'The Agency produces and adequately maintains written records of all Procurement and contract documents, including completeness of STEP.',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:10,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'B.3',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The Agency has an effective complaints management system known to the bidding community to review and resolve complaints / disputes at appropriate stages of the Procurement cycle.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:11,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'B.4',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The Agency is not impacted by fraud and corruption risk.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ]
      ]
    },
    {
      header: [
        'Reference',
        'No.',
        'A',
        'PROCUREMENT PROCESS',
        
        <>C/D <span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={cdtitle}><FaInfoCircle  /></span></>,
        'Y/N',
        'T',	
        'SCORE',	
        'Outline of the Action Plan for Improvement of Areas of Opportunity identified',
      ],
      left: 'PROCUREMENT PROCESS',
      rows:[
        [
          {
            type:'number',
            min:0,
            value:12,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'C.1',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'Procurement methods are chosen appropriate for the market / type, value and complexity of the procurement and are consistent with the legal framework.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:13,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.2',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'Clear procurement documents proportionate to the need, are used to encourage broad participation from potential competitors.',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:14,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.3',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Procedures for bid submission, receipt and opening are clearly described in the procurement documents and complied with.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:15,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.4',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Bid evaluation is based on the criteria stated in the procurement documents and to award the contract.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:16,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.5',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Procurement notices and Contract awards are announced as prescribed.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:17,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.6',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The Agency effectively manages contracts to ensure delivery as per the contract conditions.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:18,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.7',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The selection and award process are carried out effectively, efficiently and in a transparent way.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:19,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.8',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Contract performance clauses provide incentives for exceeding defined performance levels and disincentives for poor performance.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:20,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.9',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The agency has a good track record of managing environmental, social, health and safety risks including preventing sexual exploitation, rape and assault throughout the procurement process.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ]
      ]
    },
    {
      header: [
        'Reference',
        'No.',
        'A',
        'MARKET READINESS',
        
        <>C/D <span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={cdtitle}><FaInfoCircle  /></span></>,
        'Y/N',
        'T',	
        'SCORE',	
        'Outline of the Action Plan for Improvement of Areas of Opportunity identified',
      ],
      left: 'MARKET READINESS',
      rows:[
        [
          {
            type:'number',
            min:0,
            value:21,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'C.10',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'There is a clear identified target market for all procurements.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:22,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.11',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'The target market for the procurements is willing and able to respond competitively.',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:23,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.12',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The target market has a good history of delivery.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:24,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.13',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The target market views the purchaser as attractive.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:25,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.14',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'There are no restrictions to open competition in the market.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:26,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.15',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The target market is stable and does not suffer from supply chain risks.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:27,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.16',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The target market is not impacted by fraud and corruption risk.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:28,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'C.17',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The market has a good track record of managing environmental, social, health and safety risks including preventing sexual exploitation, rape and assault.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        
      ]
    },
    {
      header: [
        'Reference',
        'No.',
        'A',
        'PROCUREMENT COMPLEXITY',
        
        <>C/D <span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={cdtitle}><FaInfoCircle  /></span></>,
        'Y/N',
        'T',	
        'SCORE',	
        'Outline of the Action Plan for Improvement of Areas of Opportunity identified',
      ],
      left: 'PROCUREMENT COMPLEXITY',
      rows:[
        [
          {
            type:'number',
            min:0,
            value:29,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'D.1',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'The Procurement is of low complexity where the scope of the Procurement is certain and easily quantifiable.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:30,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.2',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'The procurement will be centralized within a few implementing agencies',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:31,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.3',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The Procurement arrangements do not include complex arrangements requiring specialized skills e.g. dialogue-based arrangements that present probity risk (e.g. competitive dialogue, negotiations, PPP or BAFO).',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:32,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.4',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The delivery/contract implementation related risks are largely within the supplier’/contractor’s control.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:33,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.5',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Evaluation will be largerly based on conformance criteria',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:34,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.6',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Life cycle costs and / or rated criteria that are complex to apply will not be a major evaluation factor.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:35,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.7',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Contract terms and conditions will largely be standard and follow available standard documents and do not require bespoke procurement documents.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:36,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'D.8',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'There is a clear understanding of contract risk allocation (borrower v supplier) and cost premiums which will support appropriate risk transfer.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        
      ]
    },
    {
      header: [
        'Reference',
        'No.',
        'A',
        'ASSESSMENT OF NATIONAL PROCEDURES (OPTIONAL)',
        
        <>C/D <span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={cdtitle}><FaInfoCircle  /></span></>,
        'Y/N',
        'T',	
        'SCORE',	
        'Outline of the Action Plan for Improvement of Areas of Opportunity identified',
      ],
      left: 'ASSESSMENT OF NATIONAL PROCEDURES (OPTIONAL)',
      rows:[
        [
          {
            type:'number',
            min:0,
            value:37,
            style:{
              minWidth:'100px'
            }
          },
          {
            max:5,
            value:'E.1',
            style:{
              minWidth:'100px'
            }
          },
          {
            as:TextArea,
            style: {
              width:'400px'
            },
            value:'Open advertising of the procurement opportunities at the national level',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              minWidth:'100px'
            }
          },
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:38,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.2',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            value:'The procurement is open to eligible firms from any country',
            style:{
              width:'400px'
            }
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:39,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.3',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'The request for bids/request for proposals document shall require that Bidders/Proposers submitting Bids/Proposals present a signed acceptance at the time of bidding, to be incorporated in any resulting contracts, confirming application of, and compliance with, the Bank’s Anti-Corruption Guidelines, including without limitation the Bank’s right to sanction and the Bank’s inspection and audit rights.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:40,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.4',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Procurement Documents shall include provisions, as agreed with the Bank, that adequately mitigate ES risks and impacts.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:41,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.5',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Contracts with an appropriate allocation of responsibilities, risks, and liabilities.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:42,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.6',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Publication of contract award information.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:43,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.7',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Rights for the Bank to review procurement documentation and activities.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:44,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.8',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'An effective complaints mechanism.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
            className:'limitedWidth'
          },
           
        ],
        [
          {
            type:'number',
            min:0,
            value:45,
            style:{
              width:'100px'
            }
          },
          {
            max:5,
            value:'E.9',
            style:{
              width:'100px'
            }
          },
          {
            as:TextArea,
            rows:"4",
            style:{
              width:'400px'
            },
            value:'Maintenance of records of the Procurement Process.',
          },
          
{
            as: Select,
            options: ['N/A','D','C'].map(e=>{return {label:e, value:e}}),
            value:'N/A',
            style:{
              width:'100px'
            }
          },
          {
            as: Select,
            options: ['Y','N'].map(e=>{return {label:e, value:e}}),
            value:'N',
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          {
            type:'number',
            min:0,
            value:0,
            style:{
              width:'100px'
            }
          },
          
          {
            as: Select,
            options: optionsActionPlan.map(e=>{return {label:e, value:e}}),
            value:'No related actions',
            style:{
              width:'70px'
            },
          },
           
        ],
        
      ]
    }
  ]);

  const [weights, setWeights] = useState([
    [20,20, null],
    [20,20,null],
    [10,10,null],
    [10,10,null],
    [20,20,null],
    [20,20,null],
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl)
    })
  },[ref.current, meta, tableData, specialists]);


  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    load()

  }, [token,country]);

  function setNewOptionsForm(options){
    let nOp = options;
    Array.from(tableData.map((e)=>{
      e.rows = e.rows.map((e)=>{
        let c = Array.from(e);
        nOp = Array.from(new Set((c[7].value ? [c[7].value] : []).concat(nOp)));
        return c;
      });
      return e;
    }));
    setOptionsActionPlan(nOp);
    nOp = nOp.map((e)=>({label:e, value:e}));
    let f = Array.from(tableData.map((e)=>{
      e.rows = e.rows.map((e)=>{
        let c = Array.from(e);
        c[7].options = nOp
        return c;
      });
      return e;
    }));
    setTableData(f);
  }

  function resetForm(){
    let f = Array.from(tableData.map((e)=>{
      e.rows = e.rows.map((e)=>{
        let c = Array.from(e);
        c[3].value = 'N/A';
        c[4].value = 'N';
        c[5].value = 0;
        c[6].value = 0;
        return c;
      });
      return e;
    }));
    setTableData(f);
  }

  async function submit(agency,project,country, isDraft=false){
    if(weights.map((e)=>e[0]).reduce((arr, a)=>arr+a) > 100.0){
      MySwal.fire('Error', 'The weights exceeded 100%. Please recalculate session weights.', 'error');
      return;
    }
    if(weights.map((e)=>e[0]).reduce((arr, a)=>arr+a) < 100.0){
      MySwal.fire('Error', 'The weights total is below 100%', 'error');
      return;
    }
    if(!meta.specialist && draft?.specialist){
      ref.current.setFieldValue('specialist', draft?.specialist);
    }
    projectRef.current.setTouched({
      country:true,
      projectId:true
    });
    let hasError = false;
    let errors = await projectRef.current.validateForm();
    if(Object.keys(errors) && Object.keys(errors).length >0){
      hasError = true;
    }

    agencyRef.current.setTouched({
      agencyId:true,
    });
     errors = await agencyRef.current.validateForm();
    if(Object.keys(errors) && Object.keys(errors).length >0){

      hasError = true;
    }

    ref.current.setTouched({
      specialist:true,
      date:true
    });
     errors = await ref.current.validateForm();
    if(Object.keys(errors) && Object.keys(errors).length >0){ 
      hasError = true;
    }

    if( !isDraft && hasError){
      MySwal.fire({
        title:'Failed to create', 
        html:'The following items are necessary: '+[
          'Assessment Date',
          'Project',
          'Agency',
          'Specialist',
        ].join(', ')+" and you must fill the table", 
        icon:'error',
        onAfterClose: ()=>{
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }
      });
      
      setErrors();
      return;
    }

    let assessmentDate = meta.assessmentDate ?? draft?.assessmentDate?.split('T')[0]?? ((new Date().toISOString()).split('T')[0]);

    let obj = {
      country: country,
      specialist: meta.specialist && meta.specialist.length > 0 ? meta.specialist : draft ? draft.specialist : null,
      agencyId: agency,
      projectId: project,
      assessmentDate: new Date(Date.parse(assessmentDate)).toISOString(),//+' '+(new Date()).toTimeString().split(' ')[0])).toISOString(),
      performanceRating: meta.performance_rating,
      possiblePoints:meta.total_possible_points,
      achievedScore: meta.achieved_score,
      achievedPercentage: meta.achieved_percentage,
      //=SE(H10/G10*100<=25;"HIGH";SE(H10/G10*100<=50;"SUBSTANTIAL";SE(H10/G10*100>=75;"LOW";SE(H10/G10*100>50;"MODERATE";""))))
      riskFactors: tableData.map((r, riskIndex)=>{
        let h = r.rows.reduce((acc,a)=>acc+a[6].value, 0);
        let g = r.rows.reduce((acc,a)=>acc+a[5].value, 0);
        let pr = (h/g) * 100;
        return {
          riskFactor: r.left,
          maximumPossiblePoints: g,
          weight: weights[riskIndex][0],
          projectPoints: h,
          riskFactorRating: pr <= 25 ? 'HIGH': pr <= 50? 'SUBSTANTIAL': pr >= 75 ? 'LOW': pr > 50 ?  'MODERATE':'',
          assessments: r.rows.map((e)=>{
            return {
              assessmentId: e[1].value,
              compulsoryOrDesirable: e[3].value,
              yOrN: e[4].value,
              total: e[5].value,
              score: e[6].value,
              status: true,
              actionPlan: e[7].value
            };
          })
        };
      })
    }

    if(!isDraft && (!obj.specialist || !obj.agencyId || !obj.projectId || !obj.assessmentDate || !obj.performanceRating)){
      MySwal.fire('Failed to create', 'The following items are necessary: '+[
        'Assessment Date',
        'Project',
        'Agency',
        'Specialist',
      ].join(', ')+" and you must fill the table", 'error');
    }else{

      await api({method:isDraft && draft ? 'patch':'post',url:isDraft? '/riskAssessments/drafts'+(draft?._id ? '/'+draft?._id : '' ) : '/riskAssessments',data: obj}).then(async (e)=>{
        let i = isDraft && draft?'updated':'created';
        let i2 = isDraft && draft?'update':'create';
        if(e.status == 201 || e.status == 200){
          
          if(!isDraft){
            projectRef.current.setFieldValue('country','');
            projectRef.current.setFieldValue('projectId','');

            agencyRef.current.setFieldValue('agencyId','');

            ref.current.setFieldValue('date',(new Date().toISOString()).split('T')[0]);
            ref.current.setFieldValue('specialist',(new Date().toISOString()).split('T')[0]);
            
            // draft.specialist = '';
            draft = null;
            
            setMeta({});
            resetForm();
            
            MySwal.fire({
              showConfirmButton: false,
              showDenyButton: false,
              showCancelButton: false,
              title:'Successfully '+i+' '+(isDraft ? ' draft':''), 
              html:<>
              Want to download the PDF?
              <div className='row'>
                <div className='col-6'>
                  <ButtonPrimary 
                    style={{width:'100px'}} 
                    onClick={()=>MySwal.close()}>
                      No
                  </ButtonPrimary>
                </div>
                <div className='col-6'>
                  <ButtonPrimary 
                    onClick={(e)=>{
                      MySwal.close();
                      MySwal.fire({
                        showConfirmButton: false,
                        showDenyButton: false,
                        showCancelButton: false,
                        title:'Preparing...', 
                        html:<></>
                      });
                      api({
                          url: 'riskAssessments/download?format=pdf&projectId='+encodeURIComponent(obj.projectId)+'&agencyId='+encodeURIComponent(obj.agencyId)+'&assessmentDate='+encodeURIComponent(obj.assessmentDate), //your url
                          method: 'GET',
                          responseType: 'blob', // important
                      }).then((response) => {
                          MySwal.close();
                          if(response.status == 200){
                              let f = response.headers['content-disposition'] ? response.headers['content-disposition'].split('filename=')[1]:null;
                              const url = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = url;
                              let format = response.headers['content-type'].includes('spreadsheet') ? 'xlsx':'pdf';
                              link.setAttribute('download', f ? f : 'riskAssessments'+"."+'pdf'); //or any other extension
                              document.body.appendChild(link);
                              link.click();
                          }else{
                              if(response && response.data){
                                  handleError(response.data);
                              }
                          }
                      }).catch((e)=>{
                          if(e.response && e.response.data){
                              handleError(e.response.data);
                          }
                      });
                    }}
                    type="submit" 
                    style={{width:'100px'}}
                    className='btn btn-primary' >Yes</ButtonPrimary>
                </div>
              </div>
              </>, 
              icon:'success'
            });
          }else{
            MySwal.fire({
              showConfirmButton: true,
              showDenyButton: false,
              showCancelButton: false,
              title:'Successfully '+i+' '+(isDraft ? ' draft':''), 
              icon:'success'
            });
          }

        }else{
          MySwal.fire('Failed to '+i2+' '+(isDraft ? ' draft':''), e.data, 'error');
        }
      }).catch((e)=>{
        let x = e?.response?.data ?? {};
        let i2 = isDraft && draft?'update':'create';
        
        MySwal.fire('Failed to '+i2+' '+(isDraft ? ' draft':''), Array.isArray(x?.message) ? x?.message?.map((e)=>e.message)?.join('<br>') : x?.message, 'error');
      });
    }

  }

  async function handleError(data){
    let f = JSON.parse(await data.text());
    MySwal.fire('Ops!',f.message,'error');
}

  function load(){
    let query = '';
    if(country){
      query = '?country='+country;
    }
    setLoading(true);
    api.get('/riskAssessments/selectOptions'+query).then((e)=>{
      setProjects(sortFilters({project:e.data.project}).project);
      setAgencies(sortFilters({agency:e.data.agency}).agency);

      let nmeta = {...meta}
      let specs = sortFilters({spec:e.data.specialist}).spec;
      if(nmeta.specialist){
        if(!specs.find((e)=>e._id == nmeta.specialist)){
          delete nmeta.specialist;
          setMeta(nmeta);
        }
      }
      setSpecialists(specs);
      let op = ["New action plan"].concat(Array.from(new Set(e.data.actionPlan.concat(e.data.customActionPlans))));
      setOptionsActionPlan(op);
      setNewOptionsForm(op);
      setLoading(false);

    });
  }

  function recalcule(o, blockIndex, rowIndex, i, blockRender=false){
    let ccoun = 0;
    for(let x = 0; x < blockIndex; x++){
      ccoun += tableData[x].rows.length;
    }
    let n = tableData[blockIndex].rows[rowIndex];
    n[5].value = n[3].value == 'N/A'?0:n[3].value=='D'?1:2;
    n[6].value = (n[3].value == 'N/A'?0:(n[4].value == 'Y'?(n[3].value == 'D'?1:2):0)) + (n[3].value != 'N/A' && n[4].value == 'N'? (n[3].value=='D'?-1:-2) : 0)
    //=SE(E(E11<>"N/A";F11="N");"Support changes in regulatory framework for more advanced review of procurement decisions"; "No related actions")
    n[7].value = (n[3].value != 'N/A' && n[4].value == 'N') ? n[7].value : 'No related actions';
    let nmeta = meta;
    nmeta.total_possible_points = tableData.reduce((acc,a)=>acc + a.rows.reduce((acc,a)=>Number(a[5].value) + acc,0),0);
    nmeta.achieved_score =tableData.reduce((acc,a)=>acc + a.rows.reduce((acc,a)=>a[6].value + acc,0),0);
    let pr = (nmeta.achieved_score / nmeta.total_possible_points) * 100;
    nmeta.riskFactorRating = pr <= 25 ? 'HIGH': pr <= 50? 'SUBSTANTIAL': pr >= 75 ? 'LOW': pr > 50 ?  'MODERATE':'';
    nmeta.achieved_percentage = (nmeta.achieved_score / nmeta.total_possible_points) * 100 
    nmeta.performance_rating = nmeta.achieved_percentage >= 90 ? 'HIGHLY SATISFACTORY' : nmeta.achieved_percentage >= 80 ? 'SATISFACTORY' : nmeta.achieved_percentage >= 70 ? 'MODERATELY SATISFACTORY' : nmeta.achieved_percentage >=60 ? 'MODERATELY UNSATISFACTORY' : nmeta.achieved_percentage >= 50 ? 'UNSATISFACTORY': 'HIGHLY UNSATISFACTORY';
    nmeta.performance_rating_color = nmeta.achieved_percentage >= 90 ? 'green': nmeta.achieved_percentage >= 50 ? '#FFD600': 'red';
    let cls = true;
    nmeta.performance_ratings = tableData.map((bl)=>{
      let a = {
        total_possible_points: bl.rows.reduce((acc,a)=>acc+Number(a[5].value), 0),
        achieved_score:  bl.rows.reduce((acc,a)=>a[6].value+acc,0),
      };
      a.achieved_percentage = (a.achieved_score / a.total_possible_points) * 100;
      if(bl.rows.reduce((acc,a)=>acc+(a[3].value == 'N/A' ? 0 : 1), 0) > 0){
        cls &= false;
        let pr = (a.achieved_score / a.total_possible_points) * 100;
        a.riskFactorRating = pr <= 25 ? 'HIGH': pr <= 50? 'SUBSTANTIAL': pr >= 75 ? 'LOW': pr > 50 ?  'MODERATE':'';
        a.performance_rating = a.achieved_percentage >= 90 ? 'HIGHLY SATISFACTORY' : a.achieved_percentage >= 80 ? 'SATISFACTORY' : a.achieved_percentage >= 70 ? 'MODERATELY SATISFACTORY' : a.achieved_percentage >=60 ? 'MODERATELY UNSATISFACTORY' : a.achieved_percentage >= 50 ? 'UNSATISFACTORY': 'HIGHLY UNSATISFACTORY';
        a.performance_rating_color = a.achieved_percentage >= 90 ? 'green': a.achieved_percentage >= 50 ? '#FFD600': 'red';
      }else{
        cls &= true;
        a = {
          achieved_percentage: 0,
          achieved_score: 0,
          total_possible_points: 0,
        }
        a.performance_rating = '';
        a.performance_rating_color = 'transparent';
      }
      return a;
    });

    if(cls){
      nmeta.performance_rating = '';
      nmeta.performance_rating_color = 'transparent';
    }

    if(!blockRender){
      setTableData(Array.from(tableData));
      setMeta({...nmeta});
    }
    return nmeta;
  }


  useEffect(()=>{
    if(draft){
      let mapKeyToIndex={
        'actionPlan':7,
        'assessmentId':1,
        'compulsoryOrDesirable':3,
        'score':6,
        'total':5,
        'yOrN':4,
      };
      let nmeta = null;
      let weigs = Array.from(weights);
      draft.riskFactors.forEach((e,i)=>{
        weigs[i][0] = e.weight;
        e.assessments.forEach((c,d)=>{
          Object.keys(c).forEach((k)=>{
            if(k in mapKeyToIndex && mapKeyToIndex[k]){
              tableData[i].rows[d][mapKeyToIndex[k]].value = c[k];
              nmeta = recalcule(null,i,d,null, true);
            }
          })
        });
      });
      setTableData(Array.from(tableData));
      setMeta({...nmeta});
      setWeights(weigs);
    }
  }, [draft]);

  
  function checkCanEditTable(){
    let agency = agencyRef.current.values.agencyId;
    let project = projectRef.current.values.projectId;
    let country = projectRef.current.values.country;
    let check = {
      country: country,
      specialist: meta.specialist && meta.specialist.length > 0 ? meta.specialist : draft ? draft.specialist : null,
      agencyId: agency,
      projectId: project,
    }
    
    let any = Object.values(check).some(function(e){
      return e == '' || !e;
    });

    if(any){
      MySwal.fire('Attention', 'Please fill in the following items before proceeding: Country, Agency, Name and Specialist', 'error').then((e)=>{
        setTimeout(()=>{
          window.scrollTo(0, 0)

        }, 400);
      });
    }

    return !any;
  }

  function selectModalActionPlan(blockIndex,rowIndex,i){
    function check(){
      let n = window.localStorage.getItem("newActionPlan");
      let ok = window.localStorage.getItem("newActionPlanOK");

      if(n != null && ok != null){
        if(ok && n.length >= 0){
          if(checkCanEditTable()){
            let c = Array.from(new Set([n].concat(optionsActionPlan)));
            tableData.forEach((e)=>{
              e.rows.forEach((e=>{
                e[7].options = c.map((e)=>({label:e,value:e}))
               
              }));
            })
            if(tableData[blockIndex].rows[rowIndex][3].value != 'N/A' && tableData[blockIndex].rows[rowIndex][4].value == 'N')
              tableData[blockIndex].rows[rowIndex][i].value = n;
            setOptionsActionPlan(c);
            setTableData(Array.from(tableData));
          }
          window.localStorage.removeItem("newActionPlan")
          window.localStorage.removeItem("newActionPlanOK")
        }else{
          // No Save
        }
      }else{
        setTimeout(check, 1000);
      }
    }
    setTimeout(check, 1000);
    MySwal.fire({title:"New Action Plan",html:<>
    <Formik
                    onSubmit={(values) => {}}
                    >
    <Form>
      <Field 
        as={Input}
        onChange={(v)=>{
          window.localStorage.setItem("newActionPlan", v.target.value);
        }}
      ></Field>
      <ButtonBack  type="button" onClick={()=>{
        window.localStorage.setItem("newActionPlanOK", false);
        MySwal.close();
      }}>Cancel</ButtonBack>
      <ButtonBack type="button" onClick={()=>{
        window.localStorage.setItem("newActionPlanOK", true);
        MySwal.close();
      }}>Save</ButtonBack>
    </Form>
    </Formik>
    </>, showDenyButton: false,
  showCancelButton: false, showConfirmButton: false}).then((e)=>{
      
    });
  }

  function setValueInForm(blockIndex, rowIndex, i, v){
    if(checkCanEditTable()){
      if(tableData[blockIndex].rows[rowIndex][3].value != 'N/A' && tableData[blockIndex].rows[rowIndex][4].value == 'N'){
        if(v == "New action plan"){
          selectModalActionPlan(blockIndex,rowIndex,i);
        }
      }
      tableData[blockIndex].rows[rowIndex][i].value = v;
      ref.current.setFieldValue('block.'+blockIndex+'.rows.'+rowIndex+'.'+i+'.value',v);
      let nmeta = recalcule(null,blockIndex,rowIndex,i);
      nmeta.touched = nmeta.touched || {};
      nmeta.touched[blockIndex] = nmeta.touched[blockIndex] || {};
      nmeta.touched[blockIndex][rowIndex] = nmeta.touched[blockIndex][rowIndex] || {};
      nmeta.touched[blockIndex][rowIndex][i] = true;
      setTableData(Array.from(tableData));
      setMeta({...nmeta});
    }else{
      setTableData(Array.from(tableData));
    }
  }

  function renderTdAction(blockIndex,rowIndex, i,tdata){
    let tg = i == 3 ? 'compulsoryOrDesirable':i==4?'yOrN':null;
    if(tg){
      if(!(meta.touched && meta.touched[blockIndex] && meta.touched[blockIndex][rowIndex] && meta.touched[blockIndex][rowIndex][i])){
        if(draft){
          try{
            tableData[blockIndex].rows[rowIndex][i].value = draft?.riskFactors[blockIndex]?.assessments[rowIndex][tg];
          }catch(e){

          }
        }
      }
    }
    return <td key={(blockIndex+1) * (i+1) * (rowIndex+1)} style={{verticalAlign: 'middle', textAlign:'center'}}>
      {
        [3,4, 7].includes(i) ?
        <Field
          as={Input}
          {...tdata}
          style={Object.assign({marginTop:'-15px'},tdata.style)}
          value={tableData[blockIndex].rows[rowIndex][i].value}
          name={'block.'+blockIndex+'.rows.'+rowIndex+'.'+i+'.value'}
          onChange={(v)=>{
            setValueInForm(blockIndex, rowIndex, i, v.target.value)
          }}
        />

        : 

        <span style={Object.assign({fontSize:'15px'},tdata.style)}>{tdata.value}</span>
      }
      
    </td>;
  }

  useEffect(()=>{
  }, [meta]);

  useEffect(()=>{
  }, [draft]);

  const [inFocus, setInFocus] = useState(false);

  const elEndRef = useRef();

  useEffect(()=>{
    if(inFocus){
      elEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [inFocus]);

  useEffect(()=>{
    // if(weights.some((e)=>!!e[2])){
    //   return;
    // }
    let a = weights.map((e)=>{
      e[2] = null;
      return e;
    });
    if(weights.map((e)=>e[0]).reduce((arr, a)=>arr+a) > 100.0){
      a = weights.map((e)=>{
        e[2] = 'The weights exceeded 100%. Please recalculate session weights.';
        return e;
      })
      // MySwal.fire('Error', 'The weights exceeded 100%. Please recalculate session weights.', 'error');
    }

    for(let x = 0; x < weights.length; x++){
      let def = weights[x][1];
      let val = weights[x][0];
      let res = (val / def);
      if(res < 0.9 || res > 1.1){
        a[x][2] = 'The value of '+val+' its above the max variation of 10% in relation to '+def;
        // MySwal.fire('Error', 'The value of '+val+' its above the max variation of 10% in relation to '+def, 'error');
      }
    }

    let set = false;

    for(let x = 0; x < weights.length; x++){
      if(a[x][2] != weights[x][2]){
        set = true;
        break;
      }
    }

    if(set){
      setWeights(Array.from(a));
    }
  }, [weights]);

  return (
    <>
        <div className="container">
          <div className="row ">
            <div className='col p-4'>
                
                <Formik
                    innerRef={ref}
                    onSubmit={(values) => {}}
                    validateOnMount
                    initialValues={{
                        specialist: meta.specialist ?? '',
                        date: meta.assessmentDate ?? (new Date().toISOString()).split('T')[0]
                    }}
                    validationSchema={
                      Yup.object({
                        specialist: Yup.string()
                          .required('Specialist is required'),
                        date: Yup.string()
                          .required('Assessment Date is required'),
                      })
                    }
                    >
                      
                    {
                    (props)=><Form>
                      <div className='row'>
                        <div className='col'>
                        <FixedCalcInfo
                    weights={weights}
                    />
                          
                          <Field 
                            as={Select}
                            name='specialist'
                            isDisabled={loading}
                            label={'Specialist'}
                            className={props.errors.specialist && props.touched.specialist ? 'text-danger' : ''}
                            onChange={(v)=>{
                              let nmeta = {...meta}
                              nmeta.specialist = v.target.value;
                              setMeta(nmeta);
                              ref.current.setFieldValue('specialist', v.target.value);

                            }}
                            value={meta.specialist ?? draft?.specialist ?? ''}
                            options={specialists.concat([{name:'SELECT...',_id:''}]).map((e)=>{return {label:e.name, value:e._id}})}
                          />

                        {props.errors.specialist && props.touched.specialist ? (
                          <div className='text-danger'>{props.errors.specialist}</div>
                        ) : null}

                          <Field 
                            as={Input}
                            label={'Assessment Date'}
                            type='date'
                            name='date'
                            className={props.errors.date && props.touched.date ? 'text-danger' : ''}
                            onChange={(v)=>{
                              let nmeta = {...meta}
                              nmeta.assessmentDate = v.target.value;
                              setMeta(nmeta);
                              ref.current.setFieldValue('date', v.target.value);
                            }}
                            value={meta.assessmentDate ?? draft?.assessmentDate?.split('T')[0] ?? (new Date().toISOString()).split('T')[0]}
                          />
                          {props.errors.date && props.touched.date ? (
                          <div className='text-danger'>{props.errors.date}</div>
                        ) : null}

                        </div>
                      </div>
                        <div className='mt-3 table-responsive' style={{}}>
                              {
                                props.touched.date && !meta.performance_rating
                                && 
                                <>
                                  <div class='text-danger text-center mt-5'>
                                  You must fill the table
                                  </div>
                                </>
                              }
                          <table className={'table limitedWidth '+(inFocus? 'tbl_overflow_hack' :'')} style={{}}>
                            {
                              (meta.performance_rating ?? '') ?
                              <thead>
                                <tr>
                                  <td colSpan='11' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>{meta.performance_rating ?? ''}</h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan='3' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>
                                    Possible Points
                                    </h3>
                                  </td>
                                  <td colSpan='3' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>
                                    Percentual Score
                                    </h3>
                                  </td>
                                  <td colSpan='4' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>
                                    Score
                                    </h3>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan='3' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>
                                      {meta.total_possible_points}
                                    </h3>
                                  </td>
                                  <td colSpan='3' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>
                                      {meta.achieved_percentage && meta.achieved_percentage > 0 ? meta.achieved_percentage.toFixed(2) : '0'}%
                                    </h3>
                                  </td>
                                  <td colSpan='4' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_rating_color ?? ''}}>
                                    <h3 style={{fontWeight:'bold'}}>
                                      {meta.achieved_score}
                                    </h3>
                                  </td>
                                </tr>
                              </thead>
                              : null
                            }
                              {
                                tableData.map((e, blockIndex)=><tbody key={blockIndex}>
                                      <tr style={{
                                        backgroundColor: '#114C6E',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontSize: '17px',
                                        verticalAlign: 'middle',
                                        fontWeight:'bold',
                                      }} 
                                      
                                      >
                                        <th colSpan={11}>
                                          <Field 
                                            as={Input}
                                            type='number'
                                            sideBySide={true}
                                            value={weights[blockIndex][0]}
                                            // className='form-control'
                                            style={{
                                              marginLeft: '20px',
                                              width:'70px'
                                            }}
                                            min={parseInt(weights[blockIndex][1] - (weights[blockIndex][1]*0.1)).to_in}
                                            max={parseInt(weights[blockIndex][1] + (weights[blockIndex][1]*0.1)).to_in}
                                            label={(<>WEIGHT</>)}
                                            suffixContent={<span data-html="true" data-bs-toggle="tooltip" data-bs-placement="top" title={"The value must vary by up to 10% of the block weight value."}>&nbsp;&nbsp;<FaInfoCircle  /></span>}
                                            onChange={(e)=>{
                                              if(checkCanEditTable()){
                                                let vr = weights[blockIndex][1] * 0.1;
                                                let min = parseInt(weights[blockIndex][1] - vr);
                                                let max = parseInt(weights[blockIndex][1] + vr);
                                                let val = Number(e.target.value);
                                                if(val < min || val > max){
  
                                                }else{
                                                  let a = weights;
                                                  a[blockIndex][0] = val;
                                                  setWeights(Array.from(a));
                                                }
                                              }
                                            }}
                                          /> 

                                          {/* <b style={{color:'yellow'}}>
                                            {weights[blockIndex][2]}
                                          </b> */}
                                        </th>
                                      </tr>
                                      <tr style={{
                                        backgroundColor: '#114C6E',
                                        color: 'white',
                                        textAlign: 'center',
                                        fontSize: '17px',
                                        verticalAlign: 'middle',
                                        fontWeight:'bold',
                                      }}>
                                        {e.header.map((c,i)=><th key={i} colSpan={i == e.header.length-1 ? 2:1}>{c}</th>)}
                                      </tr>
                                      {
                                        meta.performance_ratings && meta.performance_ratings.length > blockIndex &&
                                        <tr>
                                          <td colSpan='11' style={{textAlign:'center',color:'white',backgroundColor: meta.performance_ratings[blockIndex].performance_rating_color}}>
                                            <div className='row'>
                                              <div className='col-sm-12 col-md-6' style={{borderRight: '3px solid white'}}>
                                                <h3 style={{fontWeight:'bold'}}>RISK FACTOR: {meta.performance_ratings[blockIndex].riskFactorRating ?? ''}</h3>
                                              </div>
                                              <div className='col-sm-12 col-md-6'>
                                                <h3 style={{fontWeight:'bold'}}>PERFORMANCE: {meta.performance_ratings[blockIndex].performance_rating ?? ''}</h3>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      <tr>
                                        <td rowSpan={e.rows.length} style={
                                          {
                                            verticalAlign : 'middle',
                                            textAlign:'center',

                                          }
                                        }><h5>{e.left}</h5></td>
                                        {
                                          e.rows[0].map((e,i)=>renderTdAction(blockIndex,0,i,e))
                                        }
                                      </tr>
                                      {
                                        e.rows.slice(1).map((c,r)=><tr key={r+1}>
                                          {
                                          c.map((c,i)=>{
                                            if(c.as && c.as.name == 'Select' && ((e.rows.length-3) - r) <=0){
                                              c.onFocus = function(){
                                                setInFocus(true);
                                              }
                                              c.onBlur = function(){
                                                setInFocus(false);
                                              }
                                            }

                                            return renderTdAction(blockIndex,r+1,i,c)
                                          })
                                          }</tr>)
                                      }
                                </tbody>
                                )
                              }
                          </table>
                          <div ref={elEndRef} />
                        </div>
                    </Form>
                    }
                </Formik>
            </div>
          </div>
        </div>
    </>
  );
}

export default RiskForm;
