import React, { useState, useEffect } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import api from "../../services/api";

import Input from "../../components/Input";
import { Field, Form, Formik } from "formik";
import { ButtonBack, ButtonSubmit } from "../../components/Form/styles";
import { Button, ButtonPrimary } from "../ForgotPassword/styles";

function SpecialistForm() {
  const { token } = useAuth();
  const history = useHistory();

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  }, [token]);

  return (
    <>
        <div className="container">
          <div className="row ">
            <div className='col p-4'>
                <Formik
                    onSubmit={(values) => {}}
                    validateOnMount
                    initialValues={{
                        name:'',
                        id:'',
                        region:''
                    }}
                    >
                    {
                        (props)=><Form>
                        <Field
                            as={Input}
                            name='name'
                            label='First name'
                        />

                        <Field
                            as={Input}
                            name='id'
                            label='Last name'
                        />

                        <Field
                            as={Input}
                            name='region'
                            label='UPI'
                        />

                        <Field
                            as={Input}
                            name='region'
                            label='Email'
                        />

                        <Field
                            as={Input}
                            name='region'
                            label='Telephone'
                        />

                    </Form>
                    }
                </Formik>
            </div>
          </div>
        </div>
    </>
  );
}

export default SpecialistForm;
