import React, { useState, useEffect, useRef } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import api from "../../services/api";

import Input from "../../components/Input";
import { Field, Form, Formik } from "formik";
import { ButtonBack, ButtonSubmit } from "../../components/Form/styles";
import { Button, ButtonPrimary } from "../ForgotPassword/styles";
import Select from "../../components/Select";
import sortFilters from "../../utils/sortFilters";
import * as Yup from 'yup';
import AsyncLock from 'async-lock';

function AgencyForm({agency='0000',innerRef,country, project,onChange=()=>{}}) {
  const lock = new AsyncLock()

  const { token } = useAuth();
  const history = useHistory();
  const ref = useRef();
  if(innerRef){
    innerRef(ref);
  }

  const [agencies, setAgencies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    load();
  }, [token,country,project]);

  async function load(){ 
    console.log("AGENCY UPDATE", project);
    lock.acquire('loadAgencies', async function (release) { 
      let query = [];
      let actualData = {};
      // if(project){
      //   actualData.project = project;
      // }
      if(country){
        actualData.country = country;
      }
      if(project){
        actualData.projectId = project;
      }
      Object.keys(actualData).forEach((e) => {
        query.push(e + "=" + actualData[e]);
      });
      query = "?" + query.join("&");
      if(query == '?'){
        query = '';
      }
      setLoading(true);
      await api.get('/riskAssessments/selectOptions'+query).then((e)=>{
        async function doIt(){
          let actualValue = ref.current?.values?.agencyId;
          let agencies = sortFilters({agency:e.data.agency}).agency;
          if(actualValue){
            if(!agencies.find((e)=>e.agencyId == actualValue)){
              ref.current.setFieldValue('agencyId', '0000');
              onChange('0000','NO IMPLEMENTATION STRUCTURE EXISTS FOR THE PROJECT IN STEP');
            }else{
              ref.current.setFieldValue('agencyId', actualValue);
            }
          }else{
            ref.current.setFieldValue('agencyId', '0000');
            onChange('0000','NO IMPLEMENTATION STRUCTURE EXISTS FOR THE PROJECT IN STEP');
          }
          if(!agencies.find((e)=>e.agencyId == '0000')){
            agencies.push({name:'NO IMPLEMENTATION STRUCTURE EXISTS FOR THE PROJECT IN STEP' , agencyId: '0000'});
          }
          setAgencies(agencies);
          release();
          setLoading(false);
        };
        function check(){
          if(ref.current){
            doIt()
          }else{
            setTimeout(check, 100);
          }
        }
        check();
      });
    });
  }

  return (
    <>
        <div className="container">
          <div className="row ">
            <div className='col p-4'>
                <Formik
                    onSubmit={(values) => {}}
                    validateOnMount
                    innerRef={ref}
                    validationSchema={
                      Yup.object({
                        agencyId: Yup.string()
                          .required('Agency is required'),
                      })
                    }
                    initialValues={{
                        agencyId:agency
                    }}
                    >
                    {
                        (props)=><Form>
                        <Field
                            as={Select}
                            name='agencyId'
                            isDisabled={loading}
                            label='Agency Name'
                            className={props.errors.agencyId && props.touched.agencyId ? 'text-danger' : ''}
                            options={agencies.concat([{name:'SELECT...',agencyId:''}]).map((e)=>({label:e.name, value:e.agencyId}))}
                            onChange={(e)=>{
                              props.setFieldValue('agencyId',e.target.value);
                              onChange(e.target.value, agencies.find((c)=>c.agencyId == e.target.value)?.name);
                            }}
                        />
                         {props.errors.agencyId && props.touched.agencyId ? (
                          <div className='text-danger'>{props.errors.agencyId}</div>
                        ) : null}
                    </Form>
                    }
                </Formik>
            </div>
          </div>
        </div>
    </>
  );
}

export default AgencyForm;
