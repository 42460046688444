import React, { useEffect } from "react";

export default function FixedCalcInfo({weights=[]}){

    useEffect(()=>{

    },[]);

    return <>
        <div className="boxFixedCalc">
            <h5 style={{fontWeight:"bold"}}>WEIGHT</h5>
            <div style={{fontSize:16}}>
                {
                    (weights||[]).map((e,i)=>{
                        let alphabet = "abcdefghijklmnopqrstuvwxyz";
                        return <label key={i} style={{paddingRight:20}}><b>{alphabet[i].toUpperCase()}</b>: {e[0]}</label>
                    })
                }
                <label style={{paddingRight:20}}><b>T</b>: {weights.map((e)=>e[0]).reduce((partialSum, a) => partialSum + a, 0)}</label>
            </div>
        </div>
    </>;
}