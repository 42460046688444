import React, { useState, useEffect } from "react";

import SideBar from "../../components/SideBar";

import { useAuth } from "../../hooks/auth";
import { Container } from "../Landing/styles";

import logoImg from "../../assets/calitool-bro.svg";
import Topnav from "../../components/Topnav";

import { useHistory } from "react-router-dom";

import { Bar, HorizontalBar, Pie, Doughnut } from "react-chartjs-2";

import {
  FaArrowRight,
  FaCaretDown,
  FaCaretRight,
  FaCaretUp,
  FaEraser,
} from "react-icons/fa";
import api from "../../services/api";

import convertToChartJs from "../../utils/convertToChartJs";
import moneyAbbreviation from "../../utils/moneyAbbreviation";

import showTableImg from "../../assets/Show_table.png";
import clearFilterImg from "../../assets/clear_filter.png";
import ButtonGraphGroup from "../../components/ButtonGraphGroup";

import {backgroundColors, getOptions} from '../../utils/chartUtils';

import humanize from '../../utils/humanize';
import SAMPLE_DATA_CL from "../../seed/demoData";
import Select from "../../components/Select";
import { Field, Form, Formik } from "formik";
import Downloadable from "../../components/Downloadable";
import Swal from "sweetalert2";
import Top100ElementsChart from "./Top100ElementChart";
import withReactContent from "sweetalert2-react-content";

function Landing() {
  const SAMPLE_DATA = SAMPLE_DATA_CL("Project Metrics");
  var formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    currencyDisplay: "code",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });

  const { token, user } = useAuth();

  const history = useHistory();

  const MySwal = withReactContent(Swal);

  const [agency, setAgency] = useState("");
  const [country, setCountry] = useState("");
  const [actualData, setActualData] = useState({});

  const [reviewTypeGraph, setReviewTypeGraph] = useState([]);
  const [marketApproach, setMarketApproach] = useState([]);
  const [projectsByCountry, setProjectsByCountry] = useState([]);

  const [specialistsRank, setSpecialistsRank] = useState([]);
  const [achievedPercentage, setAchievedPercentage] = useState([]);
  const [performanceRating, setPerformanceRating] = useState([]);

  const [filterOptions, setFilterOptions] = useState([]);

  const [graphShowType, setGraphShowType] = useState({
    projectByCountry: '#'
  });
  const [dashboard, setDashboard] = useState({});
  const [geolocation, setGeolocation] = useState([]);

  const [showFilters, setShowFilters] = useState(false);
  const [map, setMap] = useState(null);

  const [expand, setExpand] = useState('');
  

  const [projectInfo, setProjectInfo] = useState('');

  useEffect(() => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    // api.get('/projectMetrics').then(response => {

    // });

    // function settfy(l){
    //   return Array.from(new Set(l));
    // }

    // setFilterOptions({
    //   countries: settfy(SAMPLE_DATA.map(e=>e.countries)),
    //   performanceRating: settfy(SAMPLE_DATA.map(e=>e.performanceRating)),
    //   projects: settfy(SAMPLE_DATA.map(e=>e.projects)),
    // })

    api.get('/users/notifications').then((e)=>{
      let f = localStorage.getItem('notificationAlert');
      if(!f){
        if(e.data.messages.length > 0){
          MySwal.fire({
            title: '',
            html: Object.values(e.data.messages[0]).shift() ?? '',
            icon: 'info',
            confirmButtonText: 'New form',
            cancelButtonText: 'Ok',
            showCancelButton: true
          }).then((e)=>{
            if(e.isConfirmed){
              history.push('/form/new');
            }
          })
        }
      }
      localStorage.setItem("notificationAlert", true);
    });

    // loadGraph();
  }, [token]);

  function loadGraph() {
    let query = [];
    Object.keys(actualData).forEach((e) => {
      query.push(e + "=" + encodeURIComponent(actualData[e]));
    });
    query = "?" + query.join("&");
    if(query == '?'){
      query = '';
    }

    // api.get("/projectMetrics/filterOptions"+query).then((response) => {
    //   if(response.data){
    //     let c = {...actualData};
    //     let doit = false;
    //     Object.keys(response.data).forEach((e)=>{
    //       if(response.data[e]){
    //         let r = response.data[e];
    //         if(r.length == 1 && actualData[e] != r[0]){
    //           c[e] = r;
    //           doit = true;
    //         }
    //       }
    //     });
    //     if(doit){
    //       setActualData(c);
    //     }
    //   }
    //   setFilterOptions(response.data);
    // });

    api.get('/projectSpecialists/graph/specialistsRank/priorSix'+query).then((response)=>{
      // let index = response.data.find
      let n = [];
      let fs = false;
      for(let x of response.data){
        if(x._id == user.name && n.length < 10){
          fs = true;
        }
        if(x._id == user.name && n.length >= 10){
          n.push(x);
        }
        if(n.length < 10){
          n.push(x);
        }else if(fs){
          break;
        }
      }
      setAchievedPercentage(n);
    })

    function littleConv(data){
      if(data != null){
        return data.map(e=>{
          console.log(!e.projectCost ? e.projectCost : '');
          return {
            label: e._id,
            value: e.total,
            total: e.total,
            projectCost: e.projectCost,
            totalAmount: e.totalAmount,
          }
        })
      }else{
        return [];
      }
    }

    api.get("/projectSpecialists/graph/specialistsRank" + query).then((response) => {
      let data = response.data.sort((a,b)=>(b.total ?? b.achievedAveragePercentage) - (a.total ?? a.achievedAveragePercentage));
      
      // let index = response.data.find
      let n = [];
      let fs = false;
      for(let x of data){
        if(x._id == user.name && n.length < 10){
          fs = true;
        }
        if(x._id == user.name && n.length >= 10){
          n.push(x);
        }
        if(n.length < 10){
          n.push(x);
        }else if(fs){
          break;
        }
      }

      setSpecialistsRank(n);
    });

    api.get("/projectSpecialists/graph/performanceRating" + query).then((response) => {
      // let f = {
      //   "HIGHLY SATISFACTORY":0, 
      //   "SATISFACTORY":1,
      //   "MODERATELY SATISFACTORY":2,
      //   "MODERATELY UNSATISFACTORY":3,
      //   "UNSATISFACTORY":4,
      //   "HIGHLY UNSATISFACTORY":5,
      // }
      // setPerformanceRating(littleConv(response.data[0]?.data).sort((e,b)=>{
      //   return f[e.label] - f[b.label] ;
      // }));
      setPerformanceRating(response.data);
    });

    

    // api.get("/activities/graph/marketApproach" + query).then((response) => {
    //   setMarketApproach(convertToChartJs(response.data));
    // });

    // api.get("/activities/graph/reviewType" + query).then((response) => {
    //   setReviewTypeGraph(convertToChartJs(response.data));
    // });

    api.get("/projectSpecialists/dashboard"+query).then((response) => {
      setDashboard(response.data);
    });

    api.get('/projectSpecialists/geolocation/me'+query).then((response)=>{
      setGeolocation(response.data);
    });
  }

  useEffect(
    function () {
      loadGraph();
    },
    [actualData]
  );


  const [mapCircles, setMapCircles] = useState({});
  const [infoWindow, setInfoWindow] = useState();


  function initMap() {

    if(infoWindow == null){
      setInfoWindow(new window.google.maps.InfoWindow({
        content: ''
      }));
    }else{
      infoWindow.close();
    }

    // Create the map.
    if(map == null){
      const map = new window.google.maps.Map(window.document.getElementById("map"), {
        zoom: 3,
        center: { lat: -16.9262801, lng: -69.3966478 },
        // mapTypeId: "terrain",
        mapId: 'af8391a6d948a37d',
        // gestureHandling: "none",
        // zoomControl: false,
      });
      setMap(map);
    }
    
    if(!map){
      return;
    }
    (map?.cts??[]).forEach((circle) => {
      remove_circle(circle);
    });

  
    map.cts = [];
    for (const city of geolocation) {
      city.geolocation.forEach(e=>{
        if(e.latitude && e.longitude && e.locationName){
          let k = e.locationName+e.latitude?.toString()+e.longitude?.toString();
          let latlng = {lat: e.latitude, lng: e.longitude};
          // Add the circle for this city to the map.
          const cityCircle = new window.google.maps.Circle({
            strokeColor: "#2D57C2",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#fff",
            fillOpacity: 0.6,
            map,
            center: latlng,
            radius: 150000,
            title: e.locationName,
            clickable:true
          });
  
          window.google.maps.event.addListener(cityCircle, 'mouseover', function(ev){
            infoWindow.setContent("<h5>"+cityCircle.title+"</h5>");
            infoWindow.setPosition(cityCircle.getCenter());
            infoWindow.open(map);
          });
          map.cts.push(cityCircle);
        }
      });
    }

    let bounds = new window.google.maps.LatLngBounds();
    if(map.cts.length > 0){
      map.cts.forEach(e=>{
        bounds.extend(e.getCenter());
      });
      map.fitBounds(bounds);
      let listener = window.google.maps.event.addListener(map, "idle", function () {
        if(map.getZoom() > 5){
          map.setZoom(5);
        }
        window.google.maps.event.removeListener(listener);
      });
    }

  }

  function remove_circle(circle) {
    // remove event listers
    window.google.maps.event.clearListeners(circle, 'click_handler_name');
    window.google.maps.event.clearListeners(circle, 'drag_handler_name');
    circle.setRadius(0);
    // if polygon:
    // polygon_shape.setPath([]); 
    circle.setMap(null);
  }

  let notSettedMap = true;
  useEffect(function(){
    let x = window.document.getElementById('map');

    if(x != null && window.google){
      initMap();
    }
  });


  return (
    <>
      <SideBar />
      <Container>
        <Topnav
          title=""
          fixedChildLayout={true}
          children={
            <h4 style={{marginTop:10}}>{projectInfo}</h4>
          }
        />
        <div className='row px-5 mx-5'>

          {
            Object.entries(dashboard).map(([e,v], i) => (
              <div key={i} className="col-6 col-md-3">
                <div className="h-100 py-1">
                  <div className="h-100">
                    <div className="card h-100">
                      <div className="card-body">
                        <div className="row ">
                          <div className="col  d-flex justify-content-between">
                            <small>{humanize(e)}</small>
                          </div>
                          <div className="col-6 text-end">
                            <h6>{isNaN(v)?v:moneyAbbreviation(Number(Number(v).toFixed(2)))}</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        <div className="container-fluid">
          <div className="row mx-5">
            <div className="col-12 text-center">
              {/* {showFilters && (
                <FaCaretDown
                  size={30}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowFilters(false);
                  }}
                />
              )}
              {!showFilters && (
                <FaCaretUp
                  size={30}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowFilters(true);
                  }}
                />
              )} */}
            </div>
            <div className='col-11 text-center'>
            <Formik
                    
                    >
              {
                    (props)=><Form>
              <div className='row mx-5 text-center'>

                {showFilters &&
                  filterOptions &&
                  Object.entries(filterOptions)
                    .map(([key, val]) => {
                      return {
                        get: function () {
                          let c = actualData || {};
                          let r = c[key];
                          
                          return r ? r : "";
                        },
                        set: function (c) {
                          let d = actualData || {};
                          d[key] = c.target.value;
                          if (!d[key]) {
                            delete d[key];
                          }
                          setActualData({ ...d });
                        },
                        options: val.map((e) => {
                          return {
                            value: e?.projectId ?? e?.agencyId ?? e?.name ?? e,
                            label: e?.name ?? e?.projectName ?? e?.projectId ?? e,
                          };
                        }).concat([{label:'SELECT...',value:''}]),
                        title: humanize(key),
                        selectPl: humanize(key),
                      };
                    })
                    .map((e, i) => (
                      <div key={i} className="col-10 col-md-2 my-1 mx-auto">
                        <div className=" h-100">
                          <div className=" h-100">
                            <div className="row h-100">
                              <div className="">
                                <div className="row">
                                  <div
                                    className="d-flex flex-column justify-content-end "
                                    style={{ height: "60px" }}
                                  >
                                    <label style={{fontSize:'15px'}}>
                                      <b>{e.selectPl}</b>
                                    </label>
                                  </div>
                                  <div className="w-100">
                                  <Field 
                                        as={Select}
                                        className={"form-select p-1 "}
                                        onChange={e.set}
                                        value={e.get()}
                                        options={e.options}
                                        style={{
                                          menu: (base) => ({
                                                ...base,
                                                width: "max-content",
                                                minWidth: "100%",
                                                color:'white',
                                                background:'black'
                                          }),
                                        }}
                                      />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
              </Form>
            }
            </Formik>
            </div>
            {showFilters &&
              <div className="col-sm-1 d-flex align-self-center my-auto">
                <div className="row ">
                  <div className="col my-1 ">
                    <button
                      className="btn  btn-outline-link "
                      title="Clear filters"
                      onClick={() => {
                        setActualData({});
                      }}
                    >
                      <FaEraser size={25} />
                    </button>
                  </div>
                  <div className="col my-1">
                    <Downloadable
                        path={'projectMetrics'}
                        innerGet={(function(){
                          let query = [];
                          Object.keys(actualData).forEach((e) => {
                            query.push(e + "=" + encodeURIComponent(actualData[e]));
                          });
                          return query.join('&');
                        })()}
                      />
                  </div>
                </div>
              </div>
            }
          </div>

          <div className="row text-center ">
            <div className={(expand == 'projectByCountry'?'col-11 col-md-10':'col-11 col-md-5')+" mx-auto text-center my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className='my-auto'>Specialists Rank</h5>
                  </div>
                  <div className="col-4">
                    <div className="btn-group">
                      <ButtonGraphGroup
                        inState={graphShowType.projectByCountry}
                        button3Title="Specialists rank by number of documents "
                        button1Hide={true}
                        button5Hide={true}
                        button2Hide={true}
                        button3Hide={false}
                        button4Hide={false}
                        expandButtonOnClick={()=>{
                          MySwal.fire({
                            confirmButtonText:'Close',
                            title:'',
                            width:'90%',
                            html:<Top100ElementsChart
                          token={token}
                          title="Specialists Rank"
                          url="/projectSpecialists/graph/specialistsRank"
                          user={user}
                            ></Top100ElementsChart>,
                            
                        
                          })
                        }}
                      /> 
                    </div>
                  </div>
                </div>
              </div>
              <div style={{
                position: 'relative',
                width: '100%'
              }}>
                <HorizontalBar
                  data={{
                    labels: specialistsRank.map((e,i) =>  (i+1)+" - "+e._id),
                    datasets: [
                      {
                        label: "",
                        data: specialistsRank.map((e) => e.total ),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={getOptions()}
                  // options={(graphShowType.projectByCountry == "#" || graphShowType.projectByCountry == "") ? Object.assign(getOptions(graphShowType.projectByCountry != "#" && graphShowType.projectByCountry != ""), 
                  // {
                  //   tooltips: {
                  //     callbacks: {
                  //       label: function (tooltipItem, data) {
                  //         let dataD = projectsByCountry[tooltipItem.index];
                  //         let d = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  //         return dataD.label+" = Count: "+d+" | Total Amount: "+moneyAbbreviation(dataD.totalAmount)+" | Project Cost: "+moneyAbbreviation(dataD.projectCost);
                  //       },
                  //     },
                  //   },
                  //   scales: {
                  //     xAxes: [{
                  //       ticks: {
                  //           beginAtZero: true,
                  //           display: false 
                  //       }
                  //   }],
                  //   yAxes: [{
                  //       ticks: {
                  //           display: false //this will remove only the label
                  //       }
                  //   }]
                  //   }
                  // }
                  // ): getOptions(graphShowType.projectByCountry != "#" && graphShowType.projectByCountry != "") }
                />
              </div>
            </div>

            <div className={(expand == 'procurementMethod'?'col-11 col-md-10':'col-11 col-md-5')+" mx-auto text-center  my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className='my-auto'>Performance Rating - {user.name}</h5>
                  </div>
                  {/* <div className="col-3" >
                    <div className="btn-group"> */}
                      {/* <ButtonGraphGroup
                        inState={graphShowType.procurementMethod == "$"}
                        button1OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            procurementMethod: "#",
                          });
                          setGraphShowType({ ...x });
                        }}
                        button2OnClick={() => {
                          let x = Object.assign(graphShowType, {
                            procurementMethod: "$",
                          });
                          setGraphShowType({ ...x });
                        }}
                        expandButtonOnClick={()=>{
                          setExpand(expand=='procurementMethod'?'':'procurementMethod')
                        }}
                      /> */}
                    {/* </div>
                  </div> */}
                </div>
              </div>
              
              <div style={{
                position: 'relative',
                width: '100%'
              }}>
                <HorizontalBar
                  data={{
                    labels: ["HIGHLY SATISFACTORY","SATISFACTORY","MODERATELY SATISFACTORY","MODERATELY UNSATISFACTORY","UNSATISFACTORY","HIGHLY UNSATISFACTORY"],
                    datasets: [
                      {
                        label: "",
                        data: ["HIGHLY SATISFACTORY","SATISFACTORY","MODERATELY SATISFACTORY","MODERATELY UNSATISFACTORY","UNSATISFACTORY","HIGHLY UNSATISFACTORY"].map((e,i)=>{
                          return performanceRating.find(c=>c._id == e)?.total ?? 0;
                        }),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={(graphShowType.projectByCountry == "#" || graphShowType.projectByCountry == "") ? Object.assign(getOptions(graphShowType.projectByCountry != "#" && graphShowType.projectByCountry != ""), 
                  {
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          let dt = data.datasets[0].data[tooltipItem.index];
                          return dt == 1 ? dt + ' project' : dt + " projects";
                        },
                      },
                    },
                    scales: {
                      xAxes: [{
                          ticks: {
                              beginAtZero: true,
                          }
                      }],
                      yAxes: [{
                          ticks: {
                          }
                      }]
                    }
                  }
                  ): getOptions(graphShowType.projectByCountry != "#" && graphShowType.projectByCountry != "") }
                />
              </div>
            </div>

            <div className={(expand == 'procurementMethod'?'col-11 col-md-10':'col-11 col-md-5')+" mx-auto text-center  my-4 animatedContainer"}>
              <div className="alert alert-secondary">
                <div className="row">
                  <div className="col my-auto">
                    <h5 className='my-auto'>Specialist Rank - last 6 months</h5>
                  </div>
                  <div className="col-3" >
                    <div className="btn-group"> 
                      <ButtonGraphGroup
                        inState={graphShowType.projectByCountry}
                        button1Hide={true}
                        button5Hide={true}
                        button2Hide={true}
                        button3Hide={true}
                        button4Hide={false}
                        expandButtonOnClick={()=>{
                          MySwal.fire({
                            confirmButtonText:'Close',
                            title:'',
                            width:'90%',
                            html:<Top100ElementsChart
                            title="Specialist Rank - last 6 months"
                            token={token}
                            user={user}
                            url="/projectSpecialists/graph/specialistsRank/priorSix"
                            ></Top100ElementsChart>})
                        }}
                      /> 
                     </div>
                  </div>
                </div>
              </div>
              
              <div style={{
                position: 'relative',
                width: '100%'
              }}>
                <HorizontalBar
                  data={{
                    labels: achievedPercentage.map((e,i) => (i+1)+" - "+e._id),
                    datasets: [
                      {
                        label: "",
                        data: achievedPercentage.map((e) => e.total),
                        backgroundColor: backgroundColors(),
                        borderWidth: 0,
                      },
                    ],
                  }}
                  options={(graphShowType.projectByCountry == "#" || graphShowType.projectByCountry == "") ? Object.assign(getOptions(graphShowType.projectByCountry != "#" && graphShowType.projectByCountry != ""), 
                  {
                    tooltips: {
                      callbacks: {
                        // label: function (tooltipItem, data) {
                        //   let dataD = achievedPercentage[tooltipItem.index];
                        //   return dataD._id+": "+dataD.total;
                        // },
                      },
                    },
                    scales: {
                      xAxes: [{
                          ticks: {
                          }
                      }],
                      yAxes: [{
                          ticks: {
                            // callback: function(val, index){
                            //   return '<b>'+val+'</b>';
                            // }
                          }
                      }]
                    }
                  }
                  ): getOptions(graphShowType.projectByCountry != "#" && graphShowType.projectByCountry != "") }
                />
              </div>
            </div>



              <div className='col-6 mx-auto my-4'>

                <div className="alert alert-secondary">
                  <div className="row">
                    <div className="col my-auto">
                      <h5 className='my-auto'>{user.name}</h5>
                    </div>
                  </div>
                </div>

                <div id='map' style={{
                  height:'100%',
                  // width:400,
                  margin: '0 auto'
                }}>
                  
                </div>
              </div>
            
          </div>
        </div>
      </Container>
    </>
  );
}

export default Landing;
